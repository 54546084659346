import React from 'react'
import RenderField from '../../components/RenderField'
export default class UserTerms extends React.Component {
  render() {
    const fields = this.props.fields

    return (<main className='userTermsPage'>
      <section className='main-section'>
        <article>
          <h1>{fields.headLine}</h1>
          <RenderField source={fields.content} />
        </article>
      </section>
    </main>)
  }
}
