const constants = require('./constants')

const pagesConfig = {
  accountMissing: {
    includeInLanguages: [
      constants.config.language.swedish.path
    ],
    pageName: 'accountMissing'
  },
  classicError: {
    includeInLanguages: [
      constants.config.language.swedish.path
    ],
    pageName: 'classicError'
  },
  loginFailed: {
    includeInLanguages: [
      constants.config.language.swedish.path
    ],
    pageName: 'loginFailed'
  }
}

const isPageEnabled = (pagesConfig, pageName, routerLanguage) => {
  return pagesConfig[pageName].includeInLanguages.includes(routerLanguage)
}

export default Object.freeze({ pagesConfig, isPageEnabled })