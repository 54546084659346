import supportedLanguages from "../language.config";

const localizationUtil = {
    updateUserLanguagePreferenceFromPath: function (firstPath, localStorage) {
        if (firstPath !== "") {
            let selectedLanguage = localizationUtil.getSupportedLanguages().find(lang => firstPath === lang.name)
            if (selectedLanguage === undefined) {
                selectedLanguage = localizationUtil.getDefaultSupportedLanguage();
            }

            localStorage.setItem("language", selectedLanguage.locale);
            return selectedLanguage;
        }
    },
    updateUserLangagePreference: function (languageName) {
        const language = supportedLanguages.find(language => language.name === languageName);
        if (language !== undefined) {
            localStorage.setItem("language", language.locale);
            return true;
        }

        return false;
    },
    getUserLanguagePreference: function (window, localStorage) {
        const firstPath = window.location.pathname.split('/')[1];

        if (localStorage.getItem('language')) {
            return localizationUtil.getLanguageFromLocale(localStorage.getItem('language'));
        }

        const selectedLanguageConfig = supportedLanguages.find(language => firstPath === language.name)

        if (selectedLanguageConfig !== undefined) {
            return selectedLanguageConfig;
        } else {
            return localizationUtil.getDefaultSupportedLanguage();
        }
    },
    getLanguageWithoutCountry: function (window, localStorage) {
        const firstPath = window.location.pathname.split('/')[1];
        if (localStorage.getItem('language')) {
            const langConfig = localizationUtil.getLanguageFromLocale(localStorage.getItem('language'));
            return langConfig.path;
        }

        const path = supportedLanguages.find(config => firstPath === config.name)
        if (path !== undefined) {
            return path.name;
        }

        return '';
    },
    getLanguageUrl: function (language) {
        if (language.split("-").length > 0) {
            const langConfig = localizationUtil.getLanguageFromLocale(localStorage.getItem('language'));
            return langConfig.path;
        }
    },
    getLanguageFromLocale: function (locale) {
        const language = supportedLanguages.find(lang => locale === lang.locale);
        if (language !== undefined) {
            return language
        }

        return this.getDefaultSupportedLanguage();
    },
    getLanguageFromLanguageName: function (languageName) {
        const language = supportedLanguages.find(lang => languageName === lang.name);
        if (language !== undefined) {
            return language
        }

        return this.getDefaultSupportedLanguage();
    },
    getSupportedLocales: function () {
        return supportedLanguages.map(config => config.locale);
    },
    getSupportedLanguages: function () {
        return supportedLanguages;
    },
    getDefaultSupportedLanguage: function () {
        return supportedLanguages.find(lang => lang.default === true);
    },
}

export default localizationUtil;