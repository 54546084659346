import React from 'react'
import { NavLink } from 'react-router-dom'
import RenderField from '../../components/RenderField'
import util from '../../util/util'

export default class CustomerCase extends React.Component {
  render() {
    const getRouteByID = util.getRouteByID
    const fields = this.props.fields
    return (<>
      <div>
        <img src={fields.customer_case_image} alt={fields.customer_case_image_alt_text} />
        <RenderField source={fields.customer_case_quote} />
        <div className='customer-case-name-title'>
          <RenderField source={fields.customer_case_name_and_title_field} />
        </div>
        <p>
          <NavLink className='button button-green' to={getRouteByID('customerCases')}>{fields.customer_case_button_text}</NavLink>
        </p>
      </div>
    </>
    )
  }
}