const constants = require('./constants.js')

module.exports = Object.freeze([
    {
        name: constants.config.language.swedish.name,
        displayName: constants.config.language.swedish.displayName,
        locale: constants.config.language.swedish.locale,
        default: constants.config.language.swedish.default,
        enabledForFrontend: constants.config.language.swedish.enabledForFrontend,
        path: constants.config.language.swedish.path,
    },
    {
        name: constants.config.language.norwegian.name,
        displayName: constants.config.language.norwegian.displayName,
        locale: constants.config.language.norwegian.locale,
        default: constants.config.language.norwegian.default,
        enabledForFrontend: constants.config.language.norwegian.enabledForFrontend,
        path: constants.config.language.norwegian.path,
    },
    {
        name: constants.config.language.english.name,
        displayName: constants.config.language.english.displayName,
        locale: constants.config.language.english.locale,
        default: constants.config.language.english.default,
        enabledForFrontend: constants.config.language.english.enabledForFrontend,
        path: constants.config.language.english.path,
    },
    {
        name: constants.config.language.danish.name,
        displayName: constants.config.language.danish.displayName,
        locale: constants.config.language.danish.locale,
        default: constants.config.language.danish.default,
        enabledForFrontend: constants.config.language.danish.enabledForFrontend,
        path: constants.config.language.danish.path,
    }
]);