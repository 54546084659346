
const constants = {
  config: {
    language: {
      swedish: {
        name: 'sv',
        displayName: 'Svenska',
        locale: 'sv-se',
        default: true,
        enabledForFrontend: true,
        path: 'sv',
      },
      norwegian: {
        name: 'no',
        displayName: 'Norsk',
        locale: 'nb-no',
        default: false,
        enabledForFrontend: true,
        path: 'no',
      },
      english: {
        name: 'en',
        displayName: 'English',
        locale: 'en-gb',
        default: false,
        enabledForFrontend: true,
        path: 'en',
      },
      danish: {
        name: 'dk',
        displayName: 'Dansk',
        locale: 'da-dk',
        default: false,
        enabledForFrontend: true,
        path: 'dk',
      }
    },
  },
  build: {
    react: {
      userAgent: 'EgreementBuild'
    }
  },
  environment: {
    "www-local": {
      subdomain: 'local',
      domain: 'egreement-labs.com'
    },
    localhost: {
      subdomain: 'local',
      domain: 'egreement-labs.com:9443'
    },
    dev: {
      subdomain: 'dev-egr',
      domain: 'egreement-labs.com'
    },
    test: { // test is qa
      subdomain: 'qa-egr',
      domain: 'egreement-labs.com'
    },
    prodtest: {
      subdomain: 'pro-prodtest',
      domain: 'egreement.com'
    },
    www: { // production
      subdomain: 'pro',
      domain: 'egreement.com'
    },
    bankID_endpoint: { sv: 'services/core/auth/57d91864-e5f7-11e6-bf01-fe55135034f3' }
  }
}

module.exports = Object.freeze(constants)
