
import FormSpreeEndPoint from "./formSpree/FormSpreeEndPoint"
const config = {
  types: {
    requestFreeAccount: 'requestFreeAccountForm',
    bookDemo: 'bookDemo',
    contact: 'contactForm',
    order: 'orderForm'
  },
  formSpree: {
    requestFreeAccountForm: new FormSpreeEndPoint('mgepzvnp'),
    bookDemoForm: new FormSpreeEndPoint('mgepzvnp'),
    contactForm: new FormSpreeEndPoint('xbqoedpm'),
    orderForm: new FormSpreeEndPoint('xgrkbvym')
  },
  inputNames: {
    company: 'company',
    email: 'email',
    name: 'name',
    sent_from: 'sent_from',
    message: 'message',
    phone: 'phone',
    productDescription: 'productDescription'
  }
}

export default config