
import React, { useRef, useEffect } from 'react'
import formsConfig from '../../../util/formIntegrations/forms.config'

const Input = (props) => {
  const { errorMessage, placeholder, type, name, required, defaultValue, pattern } = props
  const inputRef = useRef(null);
  const visible = props.visible !== false

  const onChange = () => {
    const input = inputRef.current
    input.setCustomValidity('') // This is an odd pattern, but if setCustomValidity is not reset on change validity is always false

    input.checkValidity() ? input.setCustomValidity('') : input.setCustomValidity(errorMessage)
  }

  if (formsConfig.inputNames[name] !== name) {
    throw new Error(`
  Use an allowed name.
  get name from in forms.config.js.
  If your missing a field name add as needed`)
  }

  const handleHiddenTextField = (input) => {
    input.classList.add('visually-hidden') // Hides a text field while still allowing browser focus on submit
    input.tabIndex = '-1' // Makes the field unreachable on tabbing through form
    input.setCustomValidity('') // Removes validation
  }

  useEffect(() => {
    const input = inputRef.current
    input.setCustomValidity(errorMessage)
    if (pattern) {
      input.pattern = pattern
    }

    if (!visible) {
      handleHiddenTextField(input)
    }
  }, [])

  return (
    <input
      onChange={onChange}
      ref={inputRef}
      defaultValue={defaultValue || ''}
      required={required !== false}
      placeholder={placeholder}
      type={type}
      id={name}
      name={name}
    />)
}

export default Input