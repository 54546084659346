import React from 'react'
import { NavLink } from 'react-router-dom'
import RenderField from '../../components/RenderField'
import util from '../../util/util'

export default class CustomerCaseSection extends React.Component {
  render() {
    const fields = this.props.fields
    const getRouteByID = util.getRouteByID
    return (
      <>

        <div className='cell'>
          <img alt={fields.customer_case_section_image_alt_text} src={fields.customer_case_section_image} />
          <RenderField source={fields.customer_case_section_quote} />
          <div className='nameAndTitleWrapper'>
            <RenderField source={fields.customer_case_section_name_and_title} />
          </div>
          <p>
            <NavLink className='button button-green' to={getRouteByID('customerCases')}>{fields.customer_case_section_button_text}</NavLink>
          </p>
        </div>
      </>)
  }
}
