import React from 'react'
import SeamlessSection from './SeamlessSection'
import InnovativeSection from './InnovativeSection'
import PaperFreeSection from './PaperFreeSection'
import ContactSection from './ContactSection'
import RenderField from '../../components/RenderField'
import DemoForm from '../../components/DemoForm'
export default class WebServicePage extends React.Component {
  render() {
    const fields = this.props.fields

    return (<main className='web-service-page'>
      <section className='main-section'>
        <div className='main-section-text'>
          <RenderField source={fields.headline} />
          <RenderField source={fields.description} />
        </div>
        <DemoForm />
      </section>
      <section className='seamless-section'>
        <SeamlessSection fields={fields} />
      </section>
      <hr className='orange' />
      <section className='innovative-section'>
        <InnovativeSection fields={fields} />
      </section>
      <hr className='orange' />
      <section className='paper-free-section'>
        <PaperFreeSection fields={fields} />
      </section>
      <section className='contact-section'>
        <ContactSection fields={fields} />
      </section>
    </main>)
  }
}