import React from 'react'
import { NavLink } from 'react-router-dom'
import util from '../../util/util'

export default class ServiceDropdown extends React.Component {
  constructor() {
    super()
    this.linkIsActive = (id) => {
      id = id.replace(/[/]/g, '')

      const element = document.querySelector(`#${id}`)
      const serviceLink = document.querySelector('#service-link')
      const pricesLink = document.querySelector('#prices-link')

      if (window.location.href.endsWith(id)) {
        if (element !== null) {
          pricesLink.classList.remove('active')
          "ontouchstart" in document.documentElement ?
            serviceLink.classList.remove('active')
            :
            serviceLink.classList.add('active')
          window.innerWidth <= 1024 ? serviceLink.classList.remove('active') : serviceLink.classList.add('active')
        }
      } else {
        if (document.querySelector(`#${id}`) !== null) {
          element.classList.remove('active')
        }
      }
    }
  }

  render() {
    const { getRouteByID, getRouteTextByID, getRouteToSubPageByIDs } = util

    return (
      <ul className='service-dropdown-ul'>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('eSignAPI')} className={`text-li ${this.linkIsActive(getRouteByID('eSignAPI'))}`} to={`${getRouteToSubPageByIDs('service', 'eSignAPI')}`}>{getRouteTextByID('eSignAPI')}</NavLink>
        </li>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('webServicePage')} className={`text-li ${this.linkIsActive(getRouteByID('webServicePage'))}`} to={`${getRouteToSubPageByIDs('service', 'webServicePage')}`}>{getRouteTextByID('webServicePage')}</NavLink>
        </li>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('bankIDPage')} className={`text-li ${this.linkIsActive(getRouteByID('bankIDPage'))}`} to={`${getRouteToSubPageByIDs('service', 'bankIDPage')}`}>{getRouteTextByID('bankIDPage')}</NavLink>
        </li>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('digitalForms')} className={`text-li ${this.linkIsActive(getRouteByID('digitalForms'))}`} to={`${getRouteToSubPageByIDs('service', 'digitalForms')}`}>{getRouteTextByID('digitalForms')}</NavLink>
        </li>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('digitalForms')} className={`text-li ${this.linkIsActive(getRouteByID('legalAndTechnicalSecurity'))}`} to={`${getRouteToSubPageByIDs('service', 'legalAndTechnicalSecurity')}`}>{getRouteTextByID('legalAndTechnicalSecurity')}</NavLink>
        </li>
      </ul >
    )
  }
}
