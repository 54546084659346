import React from 'react'
import RenderField from '../../components/RenderField'
import { NavLink } from 'react-router-dom'
import util from '../../util/util'

export default class PaperFreeSection extends React.Component {
  render() {
    const fields = this.props.fields
    const getRouteByID = util.getRouteByID
    return (
      <div className='cell'>
        <a href='https://www.stockholmshem.se'><img alt={fields.paper_free_section_image_alt_text} src={fields.paper_free_section_image} /></a>
        <RenderField source={fields.paper_free_section_quote} />
        <div className='customer-section-name-title'>
          <RenderField source={fields.paper_free_section_name_title} />
        </div>
        <p>
          <NavLink className='button button-green' to={getRouteByID('customerCases')}>{fields.paper_free_section_button_text}</NavLink>
        </p>
      </div>
    )
  }
}