import React from 'react'
import RenderField from '../../components/RenderField'

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { searchResults: [] }
    this.sectionRefs = props.sectionRefs
    this.fields = props.fields
    this.sections = props.fields.sections
    this.searchSnippet = React.createRef()
    this.togglePlusAndMinusSign = this.props.togglePlusAndMinusSign
    this.toggleVisibility = this.props.toggleVisibility
    this.inToView = this.props.inToView
    this.questions = props.fields.sections.map((section, index) => {
      const parentRef = this.sectionRefs
      section.h3_and_p.forEach((question, j) => {
        question.parentRef = parentRef[index].ref
        question.refPlusSign = parentRef[index].refPlusSign
        question.questionsRef = parentRef[index].questionsRef[j].questionRef
        question.questionRefPlusSign = parentRef[index].questionsRef[j].refPlusSign
        question.anchor = parentRef[index].questionsRef[j].anchor
      })
      return section.h3_and_p
    }).reduce((base, question) => {
      return base.concat(question)
    })

    this.emptySearchResults = () => {
      this.setState({ searchResults: [] })
    }

    this.showSearchResults = () => {
      const searchSnippet = this.searchSnippet.current
      searchSnippet.classList.remove('hidden')
    }

    this.hideSearchResults = () => {
      const searchSnippet = this.searchSnippet.current
      searchSnippet && searchSnippet.classList.add('hidden')
    }

    this.cleanString = (string) => {
      return string.replaceAll(/[^\w\s]/gi, '').trim()
    }

    this.findKeywordMatch = (keywords, input) => {
      let match = false

      if (keywords) {
        const inputAsArray = this.cleanString(input).split(' ')
        keywords.forEach(keyword => {
          if (inputAsArray.includes(this.cleanString(keyword.word))) { match = true }
        });
      }

      return match
    }

    this.renderSearchResults = (results) => {
      const questionsToRender = results.map(question => {
        return (<div
          onClick={() => {
            this.emptySearchResults();
            this.toggleVisibility(question.questionsRef, question.parentRef);
            this.togglePlusAndMinusSign(question.refPlusSign, question.questionRefPlusSign);
            this.inToView(question.anchor)
          }}
          className='searchSnippet'
          key={question.headline}>
          <h3 className='headline-search-snipper'>
            {question.headline}
          </h3>
          <RenderField source={`${question.paragraph.slice(0, 200)}...`} />
        </div>)
      })

      this.setState({ searchResults: [...questionsToRender] })
    }

    this.search = (e, questions) => {
      this.showSearchResults()
      const input = e.target.value
      const inputRegex = new RegExp(this.cleanString(input), "gi")
      const results = questions.filter((question) => {
        const headline = this.cleanString(question.headline)
        const paragraph = this.cleanString(question.paragraph)
        const keywordMatch = this.findKeywordMatch(question.keywords, input)

        return (headline.match(inputRegex) || paragraph.match(inputRegex) || keywordMatch) && input.length > 1
      })

      this.renderSearchResults(results)
    }
  }

  render() {
    window.addEventListener('click', this.hideSearchResults)
    const fields = this.props.fields
    return (<>
      <div className='faq-search-component'>
        <input autoFocus onChange={(e) => { this.search(e, this.questions) }} placeholder={fields.textInSearchBar} type='text' />
        <div ref={this.searchSnippet} id='searchResults'>
          {this.state.searchResults}
        </div>
      </div>
    </>)
  }
}