import React from "react";
import RenderField from "../components/RenderField";

import DemoForm from '../components/DemoForm'
import VideoArticle from '../views/VideoArticle';
import { NavLink } from "react-router-dom";
import utils from "../util/util";
import dayjs from "dayjs";
import localizationUtil from "../util/localizationUtil";
export default class Nyheter extends React.Component {
  render() {
    const fields = this.props.fields;
    const routerLanguage = localizationUtil.getLanguageWithoutCountry(window, localStorage)
    const language = localizationUtil.getUserLanguagePreference(window, localStorage)
    const { getRouteByID, getRouteTextByID, getDocument } = utils;

    if (
      fields.title ===
      "Guide: Så kan fastighetsbolag digitalisera och automatisera avtal och blanketter" ||
      fields.title ===
      "Så kan e-signering hjälpa fastighetsbolag under coronapandemin"
    ) {
      const aLinks = document.querySelectorAll("a");
      aLinks.forEach((link) => {
        if (
          link.href ===
          "https://academy.egreement.com/fastighetsbolag-digitalisera-automatisera-avtal-blanketter" ||
          link.href ===
          "https://svenskarnaochinternet.se/rapporter/svenskarna-och-internet-2020/"
        ) {
          link.setAttribute("rel", "nofollow");
        }
      });
    }

    const formatDate = routerLanguage === "en" ? dayjs(fields.date).format("MMM DD, YYYY") :
      dayjs(fields.date).format("YYYY-MM-DD")
    return (
      <>
        <main className="individual-news-article" id={fields.id || ""}>
          <article className="individual-news-article-element">
            <p className="news-date">{formatDate}</p>
            <h1>{fields.title}</h1>
            {fields.videoSection &&
              <VideoArticle
                description={fields.videoSection.description}
                videoLink={fields.videoSection.videoLink}
                hasDemoLinkButton={fields.videoSection.hasDemoLinkButton}
                demoButtonText={fields.alternateDemoButtonText}
              />}
            <RenderField source={fields.content} />
            {fields.hasContactButton && (
              <NavLink
                id="contact-button"
                className="button button-blue"
                to={getRouteByID("contactPage")}
              >
                {getRouteTextByID("contactPage")}
              </NavLink>
            )}
            {(fields.nextArticleCta && fields.nextArticleCta.nextArticleLinkId) && (
              <NavLink
                id="linked-article-button"
                className="button button-blue"
                to={`${getRouteByID('newsList')}/${fields.nextArticleCta.nextArticleLinkId}`}
              >
                {fields.nextArticleCta.nextArticleAlternateText || getDocument("pages_" + language.locale, "newsList").readMoreLink}
              </NavLink>
            )}
            {
              (fields.externalCtaButton && fields.externalCtaButton.link && fields.externalCtaButton.text) && (
                <a
                  id="linked-article-button"
                  className="button button-blue"
                  href={fields.externalCtaButton.link}
                  rel="nofollow"
                >
                  {fields.externalCtaButton.text}
                </a>
              )
            }

            {(fields.nextArticleLinkId) && (
              <NavLink
                id="linked-article-button"
                className="button button-blue"
                to={`${getRouteByID('newsList')}/${fields.nextArticleLinkId}`}
              >
                {getDocument("pages_" + language.locale, "newsList").readMoreLink}
              </NavLink>
            )}
            {fields.hasDemoLinkButton && (
              <DemoForm
                buttonText={fields.alternateDemoButtonText}
              />
            )}
          </article>
        </main>
      </>
    );
  }
}
