import React from 'react'
import Table from './Table'

export default class TestAccountSection extends React.Component {
  render() {
    const { testAccountHeadline, testAccountTable } = this.props.fields

    return (
      <>
        <h2>{testAccountHeadline}</h2>
        <Table tableData={testAccountTable} />
      </>
    )
  }
}
