import React from 'react';
import RenderField from "../../components/RenderField";
export default class AboutUs extends React.Component {
    render() {
        var fields = this.props.fields;
        return (
            <main className='about-us-page'>

                <article>
                    <h1>{fields.headline}</h1>
                    <RenderField source={fields.content} />
                </article>
            </main >
        )
    }
}