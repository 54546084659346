import React from 'react'
import RenderField from '../../components/RenderField'
import { slugify } from '../../util/url'
export default class YourRightsSection extends React.Component {
  render() {
    const { yourRights, hashes } = this.props.fields

    return (<>
      <span className='anchor' id={slugify(hashes.yourRights)}></span>
      <RenderField source={yourRights} />
    </>)
  }
}