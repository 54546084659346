import React from 'react'
export default class OpenCookieSettings extends React.Component {
  deleteConsentCookie = () => {
    document.cookie = 'CookieConsent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    window.location.reload()
  }

  render() {
    const text = this.props.fields

    return (<>
      <div className='open-cookie-consent' onClick={this.deleteConsentCookie}>
        <span className='fake-link'>{text}</span>
      </div>
    </>)
  }
}