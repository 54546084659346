import React from 'react'
import RenderField from '../../components/RenderField'
import { slugify } from '../../util/url'
export default class MainSection extends React.Component {
  render() {
    const { headLine, introduction, hashes } = this.props.fields

    return (
      <>
        <h1>{headLine}</h1>
        <RenderField source={introduction} />
        <span className='personal-data-anchor' id={slugify(hashes.personalData)}></span>
      </>
    )
  }
}
