import React from 'react'
import RenderField from '../../components/RenderField'

export default class Table extends React.Component {
  render() {
    const tableData = this.props.tableData
    return (
      <div className='table-wrapper'>
        <div id='table' className='table'>
          {tableData.map((content, index) => {
            let cellClasses = ''
            if (content.row.length > 1) {
              cellClasses = 'cell multi-row-cell' // 3 cells a row (need different width to keep border)
            } else {
              cellClasses = 'cell' // regular cell, is present in all cells
            }

            if (index === 0) {
              cellClasses += ' pink-cell' // header cell
            } else if (index === 1) {
              cellClasses += ' grey-cell' // category cell
            }

            return (<div key={index} className='flex'>{
              content.row.map((cont, index) => {
                return <div key={cont.cell + index} className={`${cellClasses}`}>{<RenderField source={cont.cell} />}</div>
              })
            }
            </div>)
          })}
        </div>
      </div>
    )
  }
}