import React from 'react'
import Table from './Table'

export default class EventSection extends React.Component {
  render() {
    const { eventHeadline, eventTable } = this.props.fields

    return (
      <>
        <h2>{eventHeadline}</h2>
        <Table tableData={eventTable} />
      </>
    )
  }
}
