import React from 'react'
import Helmet from 'react-helmet'

const BodyDecorator = ({ pageCssClass, navMenuExpanded }) => {
  return (
    <Helmet>
      <body className={navMenuExpanded ? `${pageCssClass} no-scroll` : `${pageCssClass}`} />
    </Helmet>)
}

export default BodyDecorator