import React from 'react';
import RenderField from "../components/RenderField";
import { slugify } from '../util/url'

export default class Case extends React.Component {
  render() {
    const { fields } = this.props
    const createLinkForStockholmsHem = () => {
      if (fields.id === 'stockholmshem') {
        return <a href='https://www.stockholmshem.se'><img src={fields.postFeaturedImage} alt={fields.altText !== undefined ? `${fields.altText}` : ''} /></a>
      } else {
        return <img src={fields.postFeaturedImage} alt={fields.altText !== undefined ? `${fields.altText}` : ''} />
      }
    }

    return (
      <>
        <main id={slugify(`individual-case-${fields.id}`)} className='individual-case'>

          <article>
            {typeof fields.postFeaturedImage !== "undefined" &&
              createLinkForStockholmsHem()
            }

            <h1>{fields.individualTitle}</h1>
            <RenderField source={fields.content} />
          </article>
        </main>
      </>
    )
  }
}