import React from 'react';
import util from '../util/util';
import RenderField from '../components/RenderField';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import OpenCookieSettings from './CookieConsentGTM/OpenCookieSettings';
import localizationUtil from '../util/localizationUtil';
import LanguageLinks from './LanguageLinks';
import VismaFooterLogo from './VismaFooterLogo';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localizationUtil.getUserLanguagePreference(window, localStorage)
        .locale,
    };

    this.languageSwitch = React.createRef();
  }

  handleDomainSwitch = (url) => {
    window.location.href = url;
  };

  componentWillMount() {
    localizationUtil.updateUserLangagePreference(this.state.language);
  }

  render() {
    const { getRouteTextByID, getRouteByID } = util;
    var language = localizationUtil.getUserLanguagePreference(
      window,
      localStorage
    ).locale;
    let fields = util.getDocument('component_' + language, 'footer');

    return (
      <footer>
        <div className="footer-content">
          <div className="footer-column">
            <h4>{fields.addressColumnTitle}</h4>
            <RenderField source={fields.addressFooter} />
          </div>
          <div className="footer-column">
            <h4>{fields.productColumnTitle}</h4>
            <ul>
              <li>
                <NavLink to={getRouteByID('service')}>
                  {getRouteTextByID('service')}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRouteByID('customerCases')}>
                  {getRouteTextByID('customerCases')}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>{fields.supportColumnTitle}</h4>
            <ul>
              <li>
                <NavLink to={getRouteByID('supportPage')}>
                  {getRouteTextByID('supportPage')}
                </NavLink>
              </li>
              <li>
                <a href={getRouteByID('apiDocumentation')}>API</a>
              </li>
              <li>
                <NavLink to={getRouteByID('userTerms')}>
                  {getRouteTextByID('userTerms')}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRouteByID('integrityPolicy')}>
                  {getRouteTextByID('integrityPolicy')}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRouteByID('GDPR')}>
                  {getRouteTextByID('GDPR')}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>{fields.aboutUsColumnTitle}</h4>
            <ul>
              <li>
                <NavLink to={getRouteByID('contactPage')}>
                  {getRouteTextByID('contactPage')}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRouteByID('newsList')}>
                  {getRouteTextByID('newsList')}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRouteByID('aboutUs')}>
                  {getRouteTextByID('aboutUs')}
                </NavLink>
              </li>
              <li>
                <br />
                <LanguageLinks />
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className="sub-footer">
          <VismaFooterLogo fields={fields.vismaFooterLogo} />
        </div>
        <div className="sub-footer">
          <div>© 2006-{new Date().getFullYear()} Egreement AB</div>
          <OpenCookieSettings fields={fields.cookiesLink} />
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);
