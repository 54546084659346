import React from 'react'
import CaseCard from './CaseCard'

export default class CasesCards extends React.Component {
  render() {
    let cardsToRender = []
    const idsToRender = this.props.ids

    const findCase = (casesList, id) => {
      casesList.forEach(caseToRender => {
        if (caseToRender.id === id) {
          cardsToRender.push(<CaseCard case={caseToRender} />)
        }
      });
    }

    for (let i = 0; i < idsToRender.length; i++) {
      findCase(this.props.casesList, idsToRender[i])
    }

    return (<div className='cases-card-container' >
      {
        cardsToRender.map((card, index) => {
          return (<div className='case-card-wrapper' key={index}>{card}</div>)
        })
      }
    </div >)
  }
}