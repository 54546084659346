async function postForm(data, url) {
  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: data
  });

  return response.json();
}

export default postForm