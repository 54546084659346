/* eslint-disable no-restricted-globals */
import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import buildConfig from "./build.config";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { slugify } from "./util/url";
import Meta from "./components/Meta";
import HomePageContent from "./views/HomePageContent";
import Service from "./views/Service";
import NoMatch from "./views/NoMatch";
import "./stylesheets/styles.scss";
import CaseList from "./views/CasesList";
import Case from "./views/Case";
import utils from "./util/util";
import Nyheter from "./views/Nyheter";
import NyheterList from "./views/NyheterList";
import ClassicError from "./views/ClassicError";
import ScrollToTop from "./util/ScrollToTop";
import GeneralPage from "./views/GeneralPage";
import PricesBankID from "./views/PricesBankID";
import PricesDigitalForms from "./views/PricesDigitalForms";
import PricesESignWebserviceApi from "./views/PricesESignWebserviceApi";
import BankIDPage from "./views/BankIDPage";
import DigitalForms from "./views/DigitalForms";
import ESignAPI from "./views/ESignAPI";
import LoginFailed from "./views/hiddenPages/LoginFailed";
import WebServicePage from "./views/WebServicePage";
import ContactPage from "./views/ContactPage";
import AboutUs from "./views/AboutUs/";
import IntegrityPolicy from "./views/IntegrityPolicy";
import UserTerms from "./views/UserTerms";
import AccountMissing from "./views/hiddenPages/AccountMissing";
import Faq from "./views/FAQ";
import FaqQuestions from "./views/FAQ/Question";
import CookieConsentGTM from "./components/CookieConsentGTM";
import LegalAndTechnicalSecurity from "./views/LegalAndTechnicalSecurity";
import localizationUtil from "./util/localizationUtil";
import nonNavigationalPagesConfig from "./nonNavigationalPages.config"
import BodyDecorator from "./components/BodyDecorator";
import { initializeTagManagerArgs, firePageViewEvent } from './gtm/index'

const RouteWithMeta = ({ component: Component, ...props }) => {
  const [cookieConsent, setCookieConsent] = useState(document.cookie.includes("CookieConsent=true"));
  useEffect(() => {
    cookieConsent &&
      initializeTagManagerArgs()
  }, [cookieConsent])

  useEffect(() => {
    cookieConsent &&
      firePageViewEvent(`${localizationUtil.getLanguageWithoutCountry(window, localStorage)}${props.path}`)
  }, [props.path])

  const userActionForCookieNeeded =
    !cookieConsent &&
    window.navigator.userAgent !== buildConfig.react.userAgent;

  const [navMenuExpanded, setNavMenuExpanded] = useState(false);

  const navigationLayoutChangedHandler = (showMobileNavMenu) => {
    setNavMenuExpanded(showMobileNavMenu);
  }

  return (
    <Route
      {...props}
      render={(routeProps) => (
        <Fragment>
          <Meta
            {...props}
          />
          <BodyDecorator navMenuExpanded={navMenuExpanded} {...props} />

          <Navbar
            navUserToggle={false}
            onNavigationLayoutChanged={navigationLayoutChangedHandler}
          />
          <div className="container">
            <Component {...routeProps} {...props} />
          </div>

          {userActionForCookieNeeded && <CookieConsentGTM setCookieConsent={setCookieConsent} />}
          <Footer portalAdd={true} />
        </Fragment>
      )}
    />
  );
};

const App = () => {
  const firstPath = window.location.pathname.split("/")[1];
  if (firstPath === "") {
    const userLang = navigator.language || navigator.userLanguage;
    const userLangWithoutCountry = userLang.split('-')[0]
    const languageName = localizationUtil.getLanguageFromLocale(userLangWithoutCountry).name;
    window.location.href = '/' + languageName;
  } else {
    const lang = localizationUtil.updateUserLanguagePreferenceFromPath(firstPath, localStorage);
    document.documentElement.setAttribute("lang", lang.name);
  }

  const routerLanguage = localizationUtil.getLanguageWithoutCountry(window, localStorage);
  const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
  const globalSettings = utils.getDocument("settings", "global");
  const { getRouteByID, getRouteToSubPageByIDs } = utils;
  const { siteUrl, siteDescription } = globalSettings;
  const casesList = utils
    .getDocuments("kundcases_" + language)
    .filter((individualcase) => individualcase.status !== "Draft")
    .sort((case1, case2) => new Date(case2.date) - new Date(case1.date));
  const newsList = utils
    .getDocuments("nyheter_" + language)
    .filter((individualcase) => individualcase.status !== "Draft")
    .sort(
      (nyheter1, nyheter2) =>
        new Date(nyheter2.date) - new Date(nyheter1.date)
    );
  const extraPagesList = utils
    .getDocuments("genericpages_" + language)
    .filter((individualcase) => individualcase.status !== "Draft");

  const faqQuestions = utils
    .getDocument("pages_" + language, "faq")
    .sections.map((section) => {
      section.h3_and_p.forEach((question) => {
        question.parentCategory = section.headline;
        question.questionID = slugify(question.headline);
        question.relatedQuestions = section.h3_and_p;
      });
      return section.h3_and_p;
    })
    .reduce((base, questions) => {
      return (base = [...base, ...questions]);
    });

  return (
    <>
      <Router basename={`/${routerLanguage}`}>
        <ScrollToTop>
          <Switch>
            <RouteWithMeta
              path="/"
              exact
              component={HomePageContent}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "home")}
              casesList={casesList}
              globalSettings={globalSettings}
              pageCssClass={"page-index"}
              routeID='homePage'
              language={language}
            />

            <RouteWithMeta

              path={`${getRouteToSubPageByIDs('service', 'eSignAPI')}`}
              exact
              component={ESignAPI}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "e_sign_api")}
              globalSettings={globalSettings}
              pageCssClass={"pink-background"}
              language={language}
            />

            <RouteWithMeta
              path={`${getRouteToSubPageByIDs('service', 'webServicePage')}`}
              exact
              component={WebServicePage}
              description={siteDescription}
              fields={utils.getDocument(
                "pages_" + language,
                "web_service_page"
              )}
              globalSettings={globalSettings}
              pageCssClass={"pink-background"}
              language={language}
            />

            <RouteWithMeta
              path={`${getRouteToSubPageByIDs('service', 'bankIDPage')}`}
              exact
              component={BankIDPage}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "bank_id_page")}
              globalSettings={globalSettings}
              pageCssClass={"pink-background"}
              language={language}
            />

            <RouteWithMeta
              path={`${getRouteToSubPageByIDs('service', 'digitalForms')}`}
              exact
              component={DigitalForms}
              description={siteDescription}
              fields={utils.getDocument(
                "pages_" + language,
                "digital_form_page"
              )}
              globalSettings={globalSettings}
              pageCssClass={"pink-background"}
              language={language}
            />

            <RouteWithMeta
              path={`${getRouteToSubPageByIDs('service', 'legalAndTechnicalSecurity')}`}
              exact
              component={LegalAndTechnicalSecurity}
              description={siteDescription}
              pageCssClass={"pink-background"}
              fields={utils.getDocument(
                "pages_" + language,
                "legalAndTechnicalSecurity"
              )}
              globalSettings={globalSettings}
            />

            <RouteWithMeta
              path={getRouteByID("pricesBankID")}
              exact
              component={PricesBankID}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "prices_bankid")}
              globalSettings={globalSettings}
              language={language}
            />
            <RouteWithMeta
              path={getRouteByID("pricesDigitalForms")}
              exact
              component={PricesDigitalForms}
              description={siteDescription}
              fields={utils.getDocument(
                "pages_" + language,
                "prices_digital_forms"
              )}
              globalSettings={globalSettings}
              language={language}
            />
            <RouteWithMeta
              path={getRouteByID("pricesESignWebserviceApi")}
              exact
              component={PricesESignWebserviceApi}
              description={siteDescription}
              fields={utils.getDocument(
                "pages_" + language,
                "prices_e-sign_webservice_api"
              )}
              globalSettings={globalSettings}
              language={language}
            />

            <RouteWithMeta
              path={getRouteByID("service")}
              exact
              component={Service}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "tjansten")}
              globalSettings={globalSettings}
              pageCssClass={"page-tjansten"}
              language={language}
            />

            <RouteWithMeta
              path={getRouteByID("customerCases")}
              exact
              component={CaseList}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "casesList")}
              globalSettings={globalSettings}
              casesList={casesList}
              pageCssClass={"page-cases"}
              language={language}
            />

            <RouteWithMeta
              path={getRouteByID('newsList')}
              exact
              component={NyheterList}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "newsList")}
              globalSettings={globalSettings}
              newsList={newsList}
              pageCssClass={"page-nyheter"}
            />

            <RouteWithMeta
              path={getRouteByID("contactPage")}
              exact
              component={ContactPage}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "contact_page")}
              globalSettings={globalSettings}
            />

            <RouteWithMeta
              path={getRouteByID("faq")}
              exact
              component={Faq}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "faq")}
              pageCssClass={"pink-background-faq"}
              globalSettings={globalSettings}
            />

            <RouteWithMeta
              path={getRouteByID("aboutUs")}
              exact
              component={AboutUs}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "about_us")}
              globalSettings={globalSettings}
            />

            <RouteWithMeta
              path={getRouteByID("userTerms")}
              exact
              component={UserTerms}
              description={siteDescription}
              fields={utils.getDocument("pages_" + language, "userTerms")}
              globalSettings={globalSettings}
            />

            <RouteWithMeta
              path={getRouteByID("integrityPolicy")}
              exact
              component={IntegrityPolicy}
              description={siteDescription}
              fields={utils.getDocument(
                "pages_" + language,
                "integrityPolicy"
              )}
              globalSettings={globalSettings}
            />

            <RouteWithMeta
              path={getRouteByID("legalAndTechnicalSecurity")}
              exact
              component={LegalAndTechnicalSecurity}
              description={siteDescription}
              pageCssClass={"pink-background"}
              fields={utils.getDocument(
                "pages_" + language,
                "legalAndTechnicalSecurity"
              )}
              globalSettings={globalSettings}
            />
            {nonNavigationalPagesConfig.isPageEnabled(nonNavigationalPagesConfig.pagesConfig, nonNavigationalPagesConfig.pagesConfig.loginFailed.pageName, routerLanguage) &&
              <RouteWithMeta
                path={getRouteByID("loginFailed")}
                exact
                component={LoginFailed}
                description={siteDescription}
                fields={utils.getDocument(
                  "pages_" + language,
                  "inloggning_misslyckades"
                )}
                globalSettings={globalSettings}
              />
            }

            {nonNavigationalPagesConfig.isPageEnabled(nonNavigationalPagesConfig.pagesConfig, nonNavigationalPagesConfig.pagesConfig.accountMissing.pageName, routerLanguage) &&
              <RouteWithMeta
                path={getRouteByID("accountMissing")}
                exact
                component={AccountMissing}
                description={siteDescription}
                fields={utils.getDocument("pages_" + language, "konto_saknas")}
                globalSettings={globalSettings}
                key='missing account page'
              />
            }

            {nonNavigationalPagesConfig.isPageEnabled(nonNavigationalPagesConfig.pagesConfig, nonNavigationalPagesConfig.pagesConfig.classicError.pageName, routerLanguage) &&
              <RouteWithMeta
                path={getRouteByID("classicError")}
                exact
                component={ClassicError}
                description={siteDescription}
                fields={utils.getDocument("pages_" + language, "classicerror")}
                globalSettings={globalSettings}
                pageCssClass={"page-classicerror"}
                key='error page'
              />
            }

            {extraPagesList.map((extraPage, index) => {
              let path = null;
              if (extraPage.slug && extraPage.slug.length > 2) {
                path = slugify(
                  `${getRouteByID("service")}/${extraPage.slug}`
                );
              } else {
                path = slugify(
                  `${getRouteByID("service")}/${extraPage.title}`
                );
              }

              return (
                <RouteWithMeta
                  key={"extraPages-" + index}
                  path={path}
                  exact
                  component={GeneralPage}
                  description={siteDescription}
                  fields={extraPage}
                  globalSettings={globalSettings}
                  pageCssClass={
                    "page-generic page-" + slugify(extraPage.title)
                  }

                />
              );
            })}

            {faqQuestions.map((question) => {
              return (
                <RouteWithMeta
                  key={slugify(question.headline)}
                  path={`${getRouteByID("faq")}/${slugify(
                    question.headline
                  )}`}
                  exact
                  component={FaqQuestions}
                  globalSettings={globalSettings}
                  fields={question}
                  title={question.headline}
                  description={question.paragraph}
                />
              );
            })}

            {newsList.map((nyheter) => {
              const path = slugify(
                `${getRouteByID('newsList')}/${nyheter.title}`
              );
              return (
                <RouteWithMeta
                  key={path}
                  path={path}
                  exact
                  component={Nyheter}
                  fields={nyheter}
                  globalSettings={globalSettings}
                  pageCssClass={"page-nyheter"}
                />
              );
            })}

            {casesList.map((individualcase) => {
              const path = slugify(
                `${getRouteByID("customerCases")}/${individualcase.title}`
              );

              return (
                <RouteWithMeta
                  key={path}
                  path={path}
                  exact
                  component={Case}
                  fields={individualcase}
                  globalSettings={globalSettings}
                  pageCssClass={"page-cases"}
                />
              );
            })}
            <Route render={() => <NoMatch siteUrl={siteUrl} />} />
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  )
}

export default App;