import React from 'react';
import RenderField from "../../components/RenderField";
import SliderUploadFiles from './SliderUploadFilesSection'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderAddPart from './SliderAddPartSection'
import ListOfEgreementServices from './ListOfEgreementServices'
import EfficiencySection from './EfficencySection'
import IDCards from '../../components/IDCards'
import MapOfServiceSection from './MapOfServiceSection'

export default class Service extends React.Component {
  constructor(props) {
    super()
  }

  componentDidMount() {
    const { hash } = window.location
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  }

  render() {
    const fields = this.props.fields;

    return (
      <>
        <main className='service-page'>
          <section className="service-page-main-section">
            <h1>{fields.headline}</h1>
            <div>
              <RenderField source={fields.description} />
            </div>
          </section>
          <section className='id-card-section'>
            <IDCards />
          </section>
          <hr className="orange" />
          <section className="slider-section" >
            <SliderAddPart fields={fields} />
          </section>
          <hr className="orange" />
          <section className="slider-section upload-files-slider">
            <SliderUploadFiles fields={fields} />
          </section>
          <hr className="orange" />
          <section className="tjansten-04 efficiency-section">
            <EfficiencySection fields={fields} />
          </section>
          <hr className="orange" />
          <section className="map-of-service-section">
            <MapOfServiceSection fields={fields} />
          </section>
          <hr id='lista-over-egreements-tjanster' className="orange" />
          <section className="tjansten-06 list-of-egreements-services">
            <ListOfEgreementServices fields={fields} />
          </section>
        </main>
      </>
    );
  }
}