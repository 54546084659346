import React from 'react'
import Helmet from 'react-helmet'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import { withRouter } from 'react-router-dom';
import localizationUtil from "../util/localizationUtil";
import util from "../util/util";
import languageConfig from '../language.config';

const onlyTruthyValues = obj => _pickBy(obj, item => item)

const createCrossReferenceHrefLangLinks = (id, currentLanguage, siteUrl) => {
  const languagesToRender = languageConfig.filter(languageObject => languageObject.name !== currentLanguage.name && languageObject.enabledForFrontend)
  const routes = util.getMultiLanguageRoutesByID(id, currentLanguage.name)

  return languagesToRender.map((languageObject, index) => {
    return <link key={languageObject.name} rel="alternate" href={`${siteUrl}/${languageObject.name}${routes[index]}`} hreflang={languageObject.locale} />
  })
}

const Meta = props => {
  const {
    title,
    url,
    description,
    absoluteImageUrl,
    twitterSiteAccount,
    twitterCreatorAccount,
    headerScripts,
    noindex,
    canonicalLink,
    path,
    routeID,
    Favicon1,
    Favicon2,
    siteUrl
    // overwrite { title, description } if in fields or fields.meta
  } = {
    ...onlyTruthyValues(_get(props, 'globalSettings')),
    ...props,
    ...onlyTruthyValues(_get(props, 'fields')),
    ...onlyTruthyValues(_get(props, 'fields.meta'))
  }

  let pathForAlternateLink = ''

  if (path === '/') {
    pathForAlternateLink = ''
  } else {
    pathForAlternateLink = path
  }

  const currentLanguage = localizationUtil.getUserLanguagePreference(window, localStorage)

  // write headerScripts
  const headerScriptsElement = document.head.querySelector('#headerScripts')
  if (headerScripts && headerScriptsElement) {
    headerScriptsElement.outerHTML = headerScripts
  }

  const testwebSite = window.location.href.includes("netlify");
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property='og:title' content={title} />}
      {description && <meta name='description' content={description} />}
      {description && <meta property='og:description' content={description} />}
      {url && <meta property='og:type' content='website' />}
      {url && <meta property='og:url' content={url} />}
      {absoluteImageUrl && (
        <meta name='twitter:card' content='summary_large_image' />
      )}
      {absoluteImageUrl && (
        <meta property='og:image' content={absoluteImageUrl} />
      )}
      {twitterSiteAccount && (
        <meta name='twitter:site' content={twitterSiteAccount} />
      )}
      {twitterCreatorAccount && (
        <meta name='twitter:creator' content={twitterCreatorAccount} />
      )}
      {noindex && <meta name='robots' content='noindex' />}
      {canonicalLink && <link rel='canonical' href={canonicalLink} />}
      {Favicon1 && (<link rel="icon" type="image/png" sizes="16x16" href={Favicon1} />)}
      {Favicon2 && (<link rel="icon" type="image/png" sizes="32x32" href={Favicon2}></link>)}

      {
        currentLanguage.enabledForFrontend && <link key={currentLanguage.name} rel="alternate" href={`${siteUrl}/${currentLanguage.name}${pathForAlternateLink}`} hreflang={currentLanguage.locale} />
      }

      {
        routeID && createCrossReferenceHrefLangLinks(routeID, currentLanguage, siteUrl)
      }

      {testwebSite &&
        <meta name="robots" content="noindex"></meta>
      }

    </Helmet>
  )
}

export default withRouter(Meta)