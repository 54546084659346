import React from 'react'
import Slider from "react-slick";
import utils from '../../util/util'
import localizationUtil from '../../util/localizationUtil';

export default class PartnerSlider extends React.Component {
  render() {
    const fields = utils.getDocument('pages_' + localizationUtil.getUserLanguagePreference(window, localStorage).locale, 'Partners');
    const partnerImages = fields.partnerImages;

    const slickSettings = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }

        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }

    let slidersLogo = partnerImages.map(
      (partnerImage, index) => {
        return (
          <React.Fragment key={"partnerImage" + index}>
            {partnerImage.image.includes('/images/uploads/e_avrop_logotyp.webp') ?
              <a href='http://info.e-avrop.com/' > <img src={partnerImage.image} alt={partnerImage.altText} /> </a>
              :
              <img src={partnerImage.image} alt={partnerImage.altText} />
            }
          </React.Fragment>
        )
      }
    );

    return (<div className='partner-slider-wrapper'>
      <hr className='orange hr-top' />
      <div className='partner-slider'>
        <Slider className="client-logos multiple-items" {...slickSettings}>
          {slidersLogo}
        </Slider>
      </div>
      <hr className='orange hr-bottom' />
    </div>)
  }
}
