import React from 'react'
import Table from './Table'

export default class PossibleClaimsSection extends React.Component {
  render() {
    const { possibleClaimsTable } = this.props.fields

    return (
      <>
        <Table tableData={possibleClaimsTable} />
      </>
    )
  }
}
