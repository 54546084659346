import React from "react";
import languageConfig from "../language.config";
export default class LanguageLinks extends React.Component {
    render() {
        const separator = <span> &nbsp;|&nbsp;</span>

        return languageConfig
            .filter(lang => lang.enabledForFrontend)
            .map((lang, index, filteredArray) => {
                return (<span key={lang.name}><a href={`/${lang.path}`} title={lang.displayName}>{lang.name.toUpperCase()}</a>{index !== filteredArray.length - 1 && separator}</span>)
            })
    }
}