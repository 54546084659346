import React from 'react'
import { NavLink } from 'react-router-dom'
import util from '../../util/util'

export default class PricesDropdown extends React.Component {
  constructor() {
    super()
    this.linkIsActive = (id) => {
      id = id.replace('/', '')
      const element = document.querySelector(`#${id}`)
      const pricesLinkLi = document.querySelector('#prices-link-li')
      const pricesLink = document.querySelector('#prices-link')
      const serviceLink = document.querySelector('#service-link')

      if (window.location.href.endsWith(id)) {
        if (element !== null) {
          serviceLink.classList.remove('active')
          "ontouchstart" in document.documentElement ? pricesLink.classList.remove('active') : pricesLink.classList.add('active')
          window.innerWidth <= 1024 ? pricesLink.classList.remove('active') : pricesLink.classList.add('active')

          return 'active'
        }
      }

      if (window.location.href.includes(util.getRouteByID('service'))) {
        if (pricesLinkLi !== null) {
          element.classList.remove('active')
          return ''
        }
      }
    }
  }

  render() {
    const getRouteByID = util.getRouteByID
    const getRouteTextByID = util.getRouteTextByID

    return (
      <ul className='prices-dropdown-ul'>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('pricesESignWebserviceApi').replace('/', '')} className={`text-li ${this.linkIsActive(getRouteByID('pricesESignWebserviceApi'))}`} to={getRouteByID('pricesESignWebserviceApi')}>{getRouteTextByID('pricesESignWebserviceApi')}</NavLink>
        </li>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('pricesBankID').replace('/', '')} className={`text-li ${this.linkIsActive(getRouteByID('pricesBankID'))}`} to={getRouteByID('pricesBankID')}>{getRouteTextByID('pricesBankID')}</NavLink>
        </li>
        <li className={`dropdown-li border-hover-effect`}>
          <NavLink onClick={this.props.onNavigationClick} id={getRouteByID('pricesDigitalForms').replace('/', '')} className={`text-li ${this.linkIsActive(getRouteByID('pricesDigitalForms'))}`} to={getRouteByID('pricesDigitalForms')}>{getRouteTextByID('pricesDigitalForms')}</NavLink>
        </li>
      </ul>
    )
  }
}
