
import React from 'react'
import RenderField from '../../components/RenderField'
import Table from './Table'
import { slugify } from '../../util/url'
export default class detailedDescriptionTable extends React.Component {
  render() {
    const { detailedDescriptionIntro, detailedDescriptionTable, hashes } = this.props.fields

    return (<>
      <span id={slugify(hashes.detailedInformation)} className='anchor'></span>
      <RenderField source={detailedDescriptionIntro} />
      <Table tableData={detailedDescriptionTable} />
    </>)
  }
}