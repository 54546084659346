import React from 'react'
import Table from './Table'

export default class PublisherSection extends React.Component {
  render() {
    const { publisherTable } = this.props.fields

    return (
      <>
        <Table tableData={publisherTable} />
      </>
    )
  }
}
