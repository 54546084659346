import React from 'react'
import RenderField from '../../components/RenderField'

export default class SeamlessSection extends React.Component {
  render() {
    const fields = this.props.fields
    return (<div className='flex'>
      <div className='cell'>
        <h2>{fields.seamless_section_headline}</h2>
        <RenderField source={fields.seamless_section_content} />
      </div>
      <div className='cell'>
        <img alt={fields.seamless_section_image_alt_text} src={fields.seamless_section_image} />
      </div>
    </div>)
  }
}