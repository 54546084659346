import React from 'react'
import { slugify } from '../../util/url';
import { NavLink } from 'react-router-dom';
import Slider from "react-slick";
import util from '../../util/util';
export default class CasesSlider extends React.Component {
  render() {
    const fields = this.props.fields;
    const ClientImages = fields.ClientImages;
    const getRouteByID = util.getRouteByID

    const slickSettings = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }

        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }

    let slidersLogo = ClientImages.map(
      (clientImage, index) => {
        return (
          <React.Fragment key={"clientImages" + index}>
            {(clientImage.linkToCorrespondingCase ?
              <div>
                <NavLink to={`${getRouteByID('customerCases')}/${slugify(clientImage.linkToCorrespondingCase)}`}>
                  <img src={clientImage.image} alt={clientImage.altText} />
                </NavLink>
              </div>
              :
              <img src={clientImage.image} alt={clientImage.altText} />)}
          </React.Fragment>
        )
      }
    );

    return (<div className='cases-slider-wrapper'>
      <hr className='orange hr-top' />
      <div className='cases-slider'>
        <Slider className="client-logos multiple-items" {...slickSettings}>
          {slidersLogo}
        </Slider>
      </div>
      <hr className='orange hr-bottom' />
    </div>)
  }
}
