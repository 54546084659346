import React from 'react';
import helper from "../util/helper";
import {Parser} from 'html-to-react';
import Markdown from "react-markdown";
import breaks from 'remark-breaks';
//This class is used to render HTML or Markdown After Check
export default class RenderField  extends React.Component{
    render(){
        var source = this.props.source;
        var renderEncloser = 'p';
        var componentDiv = null;
        if(typeof this.props.renderEncloser !== "undefined" && this.props.renderEncloser !== null){
            renderEncloser = this.props.renderEncloser;
        }
        if(helper.isHTML(source)){
            var htmlToReactParser = new Parser();
            componentDiv = htmlToReactParser.parse(source);
            return componentDiv
        }
        else{
            componentDiv = <Markdown source={source} renderers={{paragraph:renderEncloser}} plugins={[breaks]}/>
        }
        return componentDiv;
    }
}