import React from 'react'
import RenderField from '../../components/RenderField'

export default class SkipPaperworkSection extends React.Component {
  render() {
    const fields = this.props.fields
    return (<>

      <div className='flex'>
        <div className='cell'>
          <h2>{fields.skip_paperwork_section_headline}</h2>
          <RenderField source={fields.skip_paperwork_section_content} />
        </div>
        <div className='cell'>

          <img src={fields.skip_paperwork_section_image} alt={fields.skip_paperwork_section_image_alt_text} />
        </div>
      </div>
    </>)
  }
}