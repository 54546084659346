import React from 'react';
import { slugify } from '../util/url';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import localizationUtil from '../util/localizationUtil';
export default class NyheterList extends React.Component {
  render() {
    const newsList = this.props.newsList
    const fields = this.props.fields

    const routerLanguage = localizationUtil.getLanguageWithoutCountry(window, localStorage)
    const newsToRender = newsList.map((newsArticle, index) => {
      const date = newsArticle.date
      const formatDate = routerLanguage === "en" ? dayjs(date).format("MMM DD, YYYY") :
        dayjs(date).format("YYYY-MM-DD")

      return (
        <article key={`news${index}`}>
          <NavLink to={slugify(`/${fields.baseNameForNewsLinks}/${newsArticle.title}`)}>
            <p className="news-date">{formatDate}</p>
            <h2>{newsArticle.title}</h2>
            <p>{newsArticle.excerpt}</p>
          </NavLink>

        </article>
      )
    })
    return (
      <>
        <main className="nyheter-list">
          <h1>{fields.headline}</h1>
          {newsToRender}
        </main>
      </>
    )
  }
}
