import React from 'react'
import DarkBackGround from '../DarkBackGround'
import util from '../../util/util'
import localizationUtil from '../../util/localizationUtil'
import RenderField from '../RenderField'
import Input from '../formComponents/Input/'
import formConfig from '../../util/formIntegrations/forms.config'

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'none',
      formIsSent: false
    }
  }

  render() {
    const { status } = this.props
    const { company, email, sent_from } = formConfig.inputNames
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
    const fields = util.getDocument('component_' + language, formConfig.types.requestFreeAccount)

    const formContent = (<div className='request-free-account-form'>
      <div lang={`${language}`} className='full-width'>
        <span onClick={() => {
          this.setState({ display: 'none' })
        }} className='closeOverlay'></span>

        <p>{fields.topParagraph}</p>
        <h2 className="blue">{fields.price}</h2>
        <h3>{fields.headline}</h3>
        <ul>
          {fields.terms.map((term, index) => {
            return (<li key={term.li + index}>{term.li}</li>)
          })}
        </ul>
        <form
          onSubmit={this.props.onSubmit}
        >
          <Input
            errorMessage={fields.companyErr}
            placeholder={fields.company}
            type='text'
            required={false}
            name={company}
          />

          <Input
            errorMessage={fields.emailErr}
            placeholder={fields.email}
            type='email'
            name={email}
          />

          <Input
            defaultValue='Testa E-avtal gratis'
            type='hidden'
            name={sent_from}
          />

          <button onClick={() => { this.setState({ display: 'flex' }) }} className='button button-blue'>{fields.buttonText}</button>
          {status === "ERROR" && <p>{fields.errorMessage}</p>}

        </form>

        <RenderField className="permission" source={fields.integrityPolicy} />
      </div>
    </div >)
    const thankYouMessage = (
      <div className='thank-you-message'>
        <span onClick={() => {
          this.setState({ display: 'none' })
        }} className='closeOverlay'></span>

        <RenderField source={fields.thankYouMessage} />

        <button
          id='requestAccountForm'
          onClick={() => { this.setState({ display: 'none' }) }}
          className='button button-blue'>{fields.doneButton}
        </button>

        <RenderField source={fields.bookDemoLink} />
      </div >)
    let content;
    if (status === 'SUCCESS') {
      content = thankYouMessage
    } else {
      content = formContent
    }

    return <DarkBackGround hide={this.state.display} content={content} buttonText={fields.entryButton} buttonStyle='button button-blue' />
  }
}
