import React from 'react'
import RenderField from "../components/RenderField"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import MobileScrollComponent from "../components/MobileScrollComponent"
import CasesCards from '../components/CasesCards'
import IDCards from '../components/IDCards'
import Developer from '../components/Developer'
import CasesSlider from '../components/CasesSlider'
import PartnerSlider from '../components/PartnerSlider'
import RequestFreeAccountForm from '../components/RequestFreeAccountForm'
import util from '../util/util'
import localizationUtil from '../util/localizationUtil'
export default class HomePageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const fields = this.props.fields;
    const casesList = this.props.casesList
    const locale = localizationUtil.getUserLanguagePreference(window, localStorage).locale
    const partnerFields = util.getDocument('pages_' + locale, 'Partners');
    const partnerTitle = partnerFields.title;
    const partnerText = partnerFields.PartnersText
    const language = locale

    return (
      <main className='standard-main'>
        <section className='mainHeader'>
          <RenderField source={fields.spanHeaderOnImageDesc} />
          <RequestFreeAccountForm />
          <MobileScrollComponent fields={fields} />
        </section>
        <section className='id-card-section'>
          <h2>{fields.contentHead}</h2>
          <RenderField source={fields.contentdescription} />
          <IDCards fields={fields} />
        </section>
        <hr className="orange" />
        <section className='cases-card-section'>
          <div className='cases-card-section-text'>
            <h2>{fields.caseCardHeader}</h2>
          </div>
          <CasesCards casesList={casesList} ids={["toyota", "stockholmshem", "vasyd", "st1"]} />
          <CasesSlider fields={fields} />
        </section>
        <section className="partner-section">
          <h2> {partnerTitle}</h2>
          <RenderField source={partnerText} />
          <CasesCards casesList={casesList} ids={["tidvis", "unit4"]} />
          <PartnerSlider />
        </section>
        {language === 'sv-se' ?
          <>
            <section className='developer-section'>
              <Developer fields={fields} />
            </section>
          </>
          :
          <section style={{ padding: '5rem 5% 6rem' }} className='developer-section'>
            <Developer fields={fields} />
          </section>
        }
      </main>
    )
  }
}