import React from 'react'
import RenderField from '../RenderField'
import { slugify } from '../../util/url';
import { NavLink } from 'react-router-dom';
import util from '../../util/util'

export default class CaseCard extends React.Component {
  render() {
    const getRouteByID = util.getRouteByID
    const createALinkForStockholmsHem = () => {
      if (this.props.case.id === 'stockholmshem') {
        return <a href='https://www.stockholmshem.se/'><img alt={this.props.case.caseCard.card_image_text} src={this.props.case.caseCard.cardImage} /></a>
      } else {
        return <img alt={this.props.case.caseCard.card_image_text} src={this.props.case.caseCard.cardImage} />
      }
    }

    return (
      <div className='case-card'>
        <div className='case-card-upper-half'>
          {createALinkForStockholmsHem()}
          <h2 className='card-title'>{this.props.case.caseCard.cardTitle}</h2>
          <p className='card-name'>{this.props.case.caseCard.name}</p>
          <p className='card-job-title'>{this.props.case.caseCard.professionalTitle}</p>
        </div>
        <hr />
        <div className='case-card-lower-half'>
          <RenderField source={this.props.case.caseCard.excerpt} />
          <p className='card-link'>
            <NavLink to={slugify(`${getRouteByID('customerCases')}/${this.props.case.title}`)}>{this.props.case.caseCard.buttonLinkText}</NavLink>
          </p>
        </div>
      </div>
    )
  }
}
