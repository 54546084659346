import React from 'react'

export default class Arrow extends React.Component {
  render() {
    const { direction, size } = this.props
    return (
      <span className={`arrow ${direction} ${size}`}>
      </span>
    )
  }
}
