import React from 'react'
import RenderField from '../RenderField'
import localizationUtil from '../../util/localizationUtil'
import { TextArea, Input } from '../formComponents/'

import formConfig from '../../util/formIntegrations/forms.config'

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'block'
    }
  }

  render() {
    const { status } = this.props;
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
    const fields = this.props.fields
    const { name, message, email, company, phone } = formConfig.inputNames

    const formContent = (<div className='flex contact-form'>

      <div className='cell contact-form-left-side'>
        <h1>{fields.headline}</h1>
        <RenderField source={fields.content} />
        <div className='contact-info'>
          <RenderField source={fields.contactInfo} />
        </div>
      </div>

      <div lang={`${language}`} className='cell contact-form-right-side'>
        <form
          onSubmit={this.props.onSubmit}
          action={this.props.action}
          method={this.props.method}
        >

          <Input
            errorMessage={fields.onInvalidName}
            placeholder={fields.nameInput}
            type="text"
            name={name}
          />

          <Input
            errorMessage={fields.onInvalidEMail}
            placeholder={fields.eMail}
            type='email'
            name={email}
          />

          <Input
            errorMessage={fields.onInvalidCompany}
            placeholder={fields.company}
            type='text'
            name={company}
          />

          <Input
            errorMessage={fields.onInvalidPhone}
            placeholder={fields.phoneLabel}
            type='tel'
            name={phone}
          />

          <TextArea
            placeholder={fields.message}
            type='text'
            name={message}
          />
          {status === "SUCCESS" ? <p>{fields.onSubmitSuccess}</p> : <button className='button button-blue'>{fields.buttonText}</button>}
          {status === "ERROR" && <p>{fields.onSubmitFail}</p>}
        </form>
      </div>

    </div >)
    return formContent
  }
}