import React from 'react'
import RenderField from '../../components/RenderField'

export default class SafetyAndSecuritySection extends React.Component {
  render() {
    const fields = this.props.fields
    return (<>
      <div className='flex'>
        <span className='cell'>
          <img id='mac-book-bank-id' alt={fields.safety_and_security_section_image_alt_text} src={fields.safety_and_security_section_image} />
        </span>
        <span className='cell'>
          <h2>{fields.safety_and_security_section_headline}</h2>
          <RenderField source={fields.safety_and_security_section_content} />
        </span>
      </div></>)
  }
}