import React from 'react';
import Slider from "react-slick";
import RenderField from '../../components/RenderField'
import DemoForm from '../../components/DemoForm'

export default class SliderAddPart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderFormOverlayShow: false
    };
  }

  render() {
    const slickSettings = {
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      lazyLoad: "progressive",
      arrows: true,
    }

    const fields = this.props.fields

    return (<>
      <div className='cell phone-cell'>
        <Slider className="single-item" {...slickSettings}>
          {fields.tjanstenslider1.map((sliderImage, index) => {
            return (
              <React.Fragment key={"tjansten1" + index}>
                <div className={"screen screen-0" + (index + 1)}>
                  <img src={sliderImage.sliderImages} alt={sliderImage.imageAltDescription}
                    id={"screen-0" + (index + 1)} />
                </div>
              </React.Fragment>
            )
          })}
        </Slider>
      </div>
      <div className="text cell">
        <h2>{fields.addPartSectionHeadline}</h2>
        <RenderField source={fields.addPartSectionDescription} />
        <DemoForm />
      </div>
    </>)
  }
}