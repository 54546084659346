import React from 'react'
import utils from '../../util/util'
import Button from '../../components/Button'
import OverlayPopup from "../../components/OverlayPopup";
import localizationUtil from '../../util/localizationUtil';
import ContactFormFullOverlay from '../../components/ContactFormFullOverlay'
import formConfig from '../../util/formIntegrations/forms.config'

export default class PricesBankID extends React.Component {
  render() {
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale
    const fields = utils.getDocument('pages_' + language, 'prices_bankid')
    return (<main className='prices prices-bankid-page'>
      <h1>{fields.headline}</h1>
      <p className='main-description'>{fields.description}</p>
      <div className='flex'>
        {
          fields.price_card.map(content => {
            return (<article key={content.headline} className='cell'>
              <h3>{content.headline}</h3>
              <hr />
              <p className='offer'>{content.offer}</p>
              <ul>
                {content.details.map((details, index) => {
                  if (details.detail.includes('+')) {
                    return <li key={index}> {details.detail}</li>
                  } else {
                    return <li key={index}> &#10004; {details.detail}</li>
                  }
                })}
              </ul>

              <Button
                overlay={<OverlayPopup component={
                  <ContactFormFullOverlay
                    type={formConfig.types.contact}
                    formSpreeEndPoint={formConfig.formSpree.contactForm}
                    productName={content.headline}
                    hasCustomContent={true}
                    formHeadline={content.formHeadline}
                    formContent={content.formContent}
                  />}
                  portalAdd={true}
                  closeOption={true} />}
                className='button button-green' text={content.openFormButton} />
            </article>)
          })
        }
      </div>
    </main>)
  }
}