import React from 'react'
import utils from '../../util/util'
import RenderField from '../RenderField'
import CookieConsent from "react-cookie-consent"
import localizationUtil from '../../util/localizationUtil'

export default class CookieConsentGTM extends React.Component {
  render() {
    const { setCookieConsent } = this.props
    const { getDocument } = utils
    const fields = getDocument('component_' + localizationUtil.getUserLanguagePreference(window, localStorage).locale, 'cookieConsentSettings')
    const { content, declineButton, acceptButton } = fields

    const handleOnAccept = () => {
      setCookieConsent(true)
    }

    const handleDecline = () => {
      setCookieConsent(false)
    }

    return (
      <div className='cookie-consent-component'>
        <CookieConsent buttonText={acceptButton} declineButtonText={declineButton} buttonClasses='consent-button' overlay enableDeclineButton onDecline={handleDecline} onAccept={handleOnAccept}>
          <RenderField source={content} />
        </CookieConsent>
      </div>
    )
  }
}
