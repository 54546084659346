import React from 'react';
import { Animated } from "react-animated-css";
import RenderField from './RenderField';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class MobileScrollComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callMeOverlayShow: false,
      activeScreenCount: 1
    }
  }

  render() {
    var fields = this.props.fields;
    var slickSettings = {
      dots: true,
      speed: 300,
      slidesToShow: 1,
      lazyLoad: "progressive",
      afterChange: current => this.setState({ activeScreenCount: current + 1 })
    }

    let phoneImagesDiv = [];
    for (var phoneImagesNo = 1; phoneImagesNo <= 3; phoneImagesNo++) {
      phoneImagesDiv.push(<div className="phone" key={"phoneslick-" + phoneImagesNo}>
        <div className="screens">
          <div className={"screen screen-0" + phoneImagesNo}>
            <img src={fields["iphoneScreen" + phoneImagesNo]} alt={fields["iphoneScreenAltText" + phoneImagesNo]} id={"screen-0" + phoneImagesNo} />
          </div>
        </div>
      </div>);
    }

    return (
      <section className="start-animation">
        <div className="slick-slider-container">
          <Slider {...slickSettings}>
            {phoneImagesDiv}
          </Slider>
        </div>
        <div className="teasers">
          <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={this.state.activeScreenCount === 1}>
            <div className="teaser teaser-02-02">
              <h3>{fields.screen2header}</h3>
              <RenderField source={fields.screen2description} />
            </div>
            <div className="teaser teaser-02-03">
              <h3>{fields.screen1header}</h3>
              <RenderField source={fields.screen1description} />
            </div>
          </Animated>
          <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={this.state.activeScreenCount === 2}>
            <div className="teaser teaser-02-03">
              <h3>{fields.screen3header2}</h3>
              <RenderField source={fields.screen3description2} />
            </div>
          </Animated>
        </div>
      </section>
    )
  }
}