import React from 'react'
import SkipPaperworkSection from './SkipPaperworkSection'
import DigitalPercs from './DigitalPercs'
import CustomerCase from './CustomerCase'
import ContactSection from './ContactSection'
import RenderField from '../../components/RenderField'
import DemoForm from '../../components/DemoForm'
export default class DigitalFormPage extends React.Component {
  render() {
    const fields = this.props.fields
    return (<main className='digital-forms-page'>
      <section className='main-section'>
        <div className='main-section-text'>
          <RenderField source={fields.headline} />
          <RenderField source={fields.description} />
        </div>
        <DemoForm />
      </section>
      <section className='skip-paperwork-section'>
        <SkipPaperworkSection fields={fields} />
      </section>
      <hr className='orange' />
      <section className='digital-percs'>
        <DigitalPercs fields={fields} />
      </section>
      <hr className='orange' />
      <section className='customer-case'>
        <CustomerCase fields={fields} />
      </section>
      <section className='contact-section'>
        <ContactSection fields={fields} />
      </section>
    </main>)
  }
}