import React from 'react'
import RenderField from '../../components/RenderField'
import DemoForm from '../../components/DemoForm'

export default class VideoArticle extends React.Component {
  render() {
    const description = this.props.description
    const videoLink = this.props.videoLink
    const demoButtonText = this.props.demoButtonText
    const hasDemoLinkButton = this.props.hasDemoLinkButton
    const startVideoFrameAt = 0.8

    return (
        <div>
            {<section className='featured-video-section'>
                {videoLink && 
                    <video autoplay="autoplay" muted loop controls>
                        <source src={videoLink + '.mp4#t=' + startVideoFrameAt} type="video/mp4" />
                        <source src={videoLink + '.ogg#t=' + startVideoFrameAt} type="video/ogg" />
                        <source src={videoLink + '.webm#t=' + startVideoFrameAt} type="video/webm" />
                    </video>}
                    {description && <RenderField source={description} />}
                {hasDemoLinkButton &&
                    <DemoForm 
                    buttonText={demoButtonText}
                    />
                 }    
            </section>}
            
        </div>)
  }
}