import React from 'react'
import Button from '../../components/Button'
import OverlayPopup from "../../components/OverlayPopup";
import ContactFormFullOverlay from '../../components/ContactFormFullOverlay'
import RenderField from '../../components/RenderField'
import formConfig from '../../util/formIntegrations/forms.config'

export default class ContactSection extends React.Component {
  render() {
    const fields = this.props.fields
    return (<>
      <h2>{fields.contact_section_headline}</h2>
      <div>
        <div>
          <RenderField source={fields.contact_section_content} />
          <Button
            fields={fields}
            overlay={<OverlayPopup component={
              <ContactFormFullOverlay
                type={formConfig.types.contact}
                formSpreeEndPoint={formConfig.formSpree.contactForm}
                productName={fields.productNameInFrom}
              />}
              portalAdd={true}
              closeOption={true} />}
            text={fields.contact_section_button_text}
            className='button button-blue' />
        </div>
      </div>
    </>)
  }
}