import React from "react";
import RenderField from "../../components/RenderField";
import { NavLink } from "react-router-dom";
import util from "../../util/util";

export default class AllNeedsSection extends React.Component {
  render() {
    const fields = this.props.fields;
    const getRouteByID = util.getRouteByID;
    return (
      <>
        <h2>{fields.all_needs_section_headline}</h2>
        <RenderField source={fields.all_needs_section_description} />
        <div className="flex">
          {fields.check_mark_card.map((card, index) => {
            return (
              <div key={index} className="cell">
                <div className="check-mark">&#10004;</div>
                <RenderField source={card.card_content} />
              </div>
            );
          })}
        </div>

        <NavLink
          className="button button-green"
          to={{
            pathname: getRouteByID("service"),
            hash: "lista-over-egreements-tjanster",
          }}
          //to={{ pathname: "/tjansten", hash: "lista-over-egreements-tjanster" }}
        >
          {fields.all_needs_section_button_text}
        </NavLink>
      </>
    );
  }
}
