import React from "react";
import RenderField from "../../components/RenderField";
import { NavLink } from "react-router-dom";
import util from "../../util/util";

export default class InnovationSection extends React.Component {
  render() {
    const fields = this.props.fields;
    const getRouteByID = util.getRouteByID;
    return (
      <>
        <h2>{fields.innovative_section_headline}</h2>
        <div className="flex">
          {fields.check_mark_card.map((card, index) => {
            return (
              <div className="cell" key={index}>
                <div className="check-mark">&#10004;</div>
                <RenderField source={card.card_content} />
              </div>
            );
          })}
        </div>
        <NavLink
          className="button button-green"
          to={{
            pathname: getRouteByID("service"),
            hash: "lista-over-egreements-tjanster",
          }}
        >
          {fields.innovative_section_button_text}
        </NavLink>
      </>
    );
  }
}
