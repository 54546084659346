import React from 'react'
import IDCard from './IDCard'
import utils from '../../util/util'
import localizationUtil from '../../util/localizationUtil'

export default class IDCards extends React.Component {
  render() {
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale
    const IDCardsList = utils.getDocuments('idCards_' + language)
    const { getRouteToSubPageByIDs } = utils

    const ESignAPI = <IDCard
      img={IDCardsList[0].image}
      image_alt_text={IDCardsList[0].image_alt_text}
      headline={IDCardsList[0].title}
      content={IDCardsList[0].content}
      checklist={IDCardsList[0].checklist}
      buttonLink={`${getRouteToSubPageByIDs('service', 'eSignAPI')}`}
      buttonText={IDCardsList[0].buttonText}
    />

    const autentisering = <IDCard
      img={IDCardsList[1].image}
      image_alt_text={IDCardsList[1].image_alt_text}
      headline={IDCardsList[1].title}
      content={IDCardsList[1].content} webServicePage
      checklist={IDCardsList[1].checklist}
      buttonLink={`${getRouteToSubPageByIDs('service', 'bankIDPage')}`}
      buttonText={IDCardsList[1].buttonText}
    />

    const digitalaBlanketter = <IDCard
      img={IDCardsList[2].image}
      image_alt_text={IDCardsList[2].image_alt_text}
      headline={IDCardsList[2].title}
      content={IDCardsList[2].content}
      checklist={IDCardsList[2].checklist}
      buttonLink={`${getRouteToSubPageByIDs('service', 'digitalForms')}`}
      buttonText={IDCardsList[2].buttonText}
    />

    const ESignWebbtjänsten = <IDCard
      img={IDCardsList[3].image}
      image_alt_text={IDCardsList[3].image_alt_text}
      headline={IDCardsList[3].title}
      content={IDCardsList[3].content}
      checklist={IDCardsList[3].checklist}
      buttonText={IDCardsList[3].buttonText}
      buttonLink={`${getRouteToSubPageByIDs('service', 'webServicePage')}`}
    />

    const cardsToRender = [ESignAPI, ESignWebbtjänsten, autentisering, digitalaBlanketter]

    return (<div className='ID-card-container' >
      {cardsToRender.map((card, index) => {
        return (<div className='ID-card' key={index}>{card}</div>)
      })}
    </div >)
  }
}