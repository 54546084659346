import React from 'react'
import RenderField from '../../components/RenderField'

export default class EfficiencySection extends React.Component {
  render() {
    const fields = this.props.fields
    return (<>
      <div className="cell">
        <img id='efficiency-illustration' src={fields.efficiencySectionImage} alt={fields.efficiencySectionImageAltText} />
      </div>
      <div className="cell">
        <h2>{fields.efficiencySectionHeadline}</h2>
        <RenderField source={fields.efficiencySectionDescription} />
      </div>
    </>)
  }
}