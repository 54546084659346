import React from 'react'
import ContactForm from '../../components/Contact_form'
import utils from '../../util/util'
import localizationUtil from '../../util/localizationUtil'
export default class ContactPage extends React.Component {
  render() {
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale
    const fields = utils.getDocument('pages_' + language, 'contact_page')

    return (
      <main className='contact-us-page'>
        <article className='flex'>
          <ContactForm fields={fields} />
        </article>
      </main>)
  }
}