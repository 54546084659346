import buildConfig from "../build.config";
import constants from '../constants'

const createEnvironmentUrl = (env, mapToSwedishBankID) => {
    const environment = constants.environment[env] || constants.environment['www'];
    const baseUrl = `https://${environment.subdomain}.${environment.domain}`;
    return `${baseUrl}${mapToSwedishBankID ? `/${constants.environment.bankID_endpoint.sv}` : '/'}`;
};
// isProduction checks the build variables in a server side pages.
const isProduction = () => window.navigator.userAgent === buildConfig.react.userAgent
const isSwedishLocale = (locale) => locale === 'sv-se'
const getSubdomain = (url) => {
    const subdomain = url.split('.')[0]
    return isProduction() ? 'www' : subdomain
};

const loginHelper = {
    resolve: function (hostname, locale) {
        const subdomain = getSubdomain(hostname);
        const mapToSwedishBankID = isSwedishLocale(locale)
        return createEnvironmentUrl(subdomain, mapToSwedishBankID)
    }
};

export default loginHelper;
