import React from 'react'
import DarkBackGround from '../DarkBackGround'
import RenderField from '../RenderField'
import util from '../../util/util'
import localizationUtil from '../../util/localizationUtil'
import Input from '../formComponents/Input'
import formConfig from '../../util/formIntegrations/forms.config'

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'none'
    }
  }

  render() {
    const { status } = this.props;
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
    const fields = util.getDocument('component_' + language, formConfig.types.bookDemo)
    const { name, company, phone, email } = formConfig.inputNames

    const formContent = (<div className='demo-form'>
      <div className='demo-form-left-side'>
        <RenderField source={fields.description} />
        <h3>{fields.headline}</h3>
      </div>
      <span onClick={() => {
        this.setState({ display: 'none' })
      }} className='closeOverlay'></span>
      <div lang={`${language}`} className='demo-form-right-side'>
        <form
          onSubmit={this.props.onSubmit}
        >

          <Input
            errorMessage={fields.nameValidationText}
            placeholder={fields.fullName}
            name={name}
            type="text"
          />

          <Input
            errorMessage={fields.EmailValidationText}
            placeholder={fields.email}
            name={email}
            type='email'
          />

          <Input
            errorMessage={fields.companyValidationText}
            placeholder={fields.company}
            name={company}
            type='text'
          />

          <Input
            errorMessage={fields.phoneValidationText}
            placeholder={fields.phoneLabel}
            name={phone}
            type='tel'
          />

          {status === "SUCCESS" ? <p>{fields.thankYouMessage}</p> : <button onClick={() => { this.setState({ display: 'block' }) }} id='dark-background-button' className='button button-blue'>{fields.buttonText}</button>}
          {status === "ERROR" && <p>{fields.errorMessage}</p>}
        </form>
      </div>

    </div >)
    return <DarkBackGround hide={this.state.display} content={formContent} buttonText={this.props.alternativeButtonText || fields.entryButton} buttonStyle='button button-blue' />
  }
}