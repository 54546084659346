import React from "react";
import { NavLink } from "react-router-dom";
import RenderField from "../../components/RenderField";
import util from "../../util/util";

export default class PopularFunctionSection extends React.Component {
  render() {
    const fields = this.props.fields;
    const getRouteByID = util.getRouteByID;
    return (
      <>
        <h2>{fields.popular_functions_section_headline}</h2>
        <div className="flex">
          {fields.check_mark_card.map((card, index) => {
            return (
              <div key={index} className="cell">
                <div className="check-mark">&#10004;</div>
                <RenderField source={card.card_content} />
              </div>
            );
          })}
        </div>
        <NavLink to={getRouteByID("pricesBankID")}>
          <button className="button button-green">
            {fields.popular_functions_section_button_text}
          </button>
        </NavLink>
      </>
    );
  }
}
