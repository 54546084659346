//this file handles the connection with form spread, you can edit Form content to in FormContent.js
import React from "react";
import Form from './Form'
import formConfig from '../../util/formIntegrations/forms.config'
import resolveFormPosts from "../../util/formIntegrations/resolveFormPosts"

export default class DemoForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;

    return (
      <Form
        status={status}
        onSubmit={this.submitForm}
        alternativeButtonText={this.props.buttonText}
      />
    );
  }

  submitForm(event) {
    resolveFormPosts([formConfig.formSpree.bookDemoForm], event).then(isResolved => {
      const status = isResolved ? 'SUCCESS' : 'ERROR'
      this.setState({ status: status })
    })
  }
}