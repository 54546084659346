import React from 'react'
import { NavLink } from 'react-router-dom'
import RenderField from '../../components/RenderField'
import util from '../../util/util'
import localizationUtil from '../../util/localizationUtil'
import { slugify } from '../../util/url'
export default class Question extends React.Component {
  render() {
    const { getRouteByID } = util
    const questionPageFields = util.getDocument('pages_' + localizationUtil.getUserLanguagePreference(window, localStorage).locale, 'questionPage')
    const { headline, paragraph, parentCategory, questionID, relatedQuestions, callToActions } = this.props.fields

    return (<main className='questions-page'>

      <NavLink className='back-to-service-arrow' to={{ pathname: getRouteByID('faq'), hash: questionID }} title={questionPageFields.textForBackArrow}>{`< `}</NavLink>
      <NavLink className='breadcrumb' to={{ pathname: getRouteByID('faq'), hash: questionID }}>{`${parentCategory}`}</NavLink>
      <h1>{headline}</h1>
      <RenderField source={paragraph} />
      {callToActions && callToActions.map(actionLink => {
        return (
          <div key={actionLink.link}>
            {actionLink.isExternalLink ?
              <span className='api-button-wrapper'>
                <a className='api-button' href={actionLink.link}>{actionLink.text}</a>
              </span>
              :
              <span className='api-button-wrapper'>
                <NavLink className='api-button' to={actionLink.link}>{actionLink.text}</NavLink>
              </span>
            }
          </div>
        )
      })}
      <h2>{questionPageFields.relatedQuestionsHeadline}</h2>
      <ul className='related-question-list'>
        {relatedQuestions.map(relatedQuestion => {
          return (
            <div key={relatedQuestion.headline}>
              {relatedQuestion.headline !== headline &&
                <li>
                  <NavLink to={`${getRouteByID('faq')}/${slugify(relatedQuestion.headline)}`}>{relatedQuestion.headline}</NavLink>
                </li>
              }
            </div>
          )
        })}
      </ul>

    </main>)
  }
}