import React from 'react'
import PricesForm from '../../components/PricesForm'
import utils from '../../util/util'
import localizationUtil from '../../util/localizationUtil'

export default class PricesDigitalForms extends React.Component {
  render() {
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale
    const fields = utils.getDocument('component_' + language, 'price_form_for_page')

    return (<>
      <main className='prices-form-page'>
        <article className='flex'>
          <PricesForm fields={fields} />
        </article>
      </main>
    </>)
  }
}