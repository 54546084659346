import prepareData from './prepareFormSpreeData'

export default class FormSpreeEndPoint {
  constructor(formId) {
    this.baseUrl = 'https://formspree.io/f'
    this.formId = formId
    this.prepareData = prepareData
  }

  get url() { return `${this.baseUrl}/${this.formId}` }

  validate(response) {
    return response.ok
  }
}