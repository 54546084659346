import React from 'react';
export default class VismaFooterLogo extends React.Component {
  render() {
    return (
      <a href="https://www.visma.com/" title="Visma Group Website">
        <img
          className="visma-footer-logo"
          src="/images/uploads/a-company-in-visma-white.svg"
          alt="Egreemnt is a company in Visma Group"
        />
      </a>
    );
  }
}
