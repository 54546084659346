import React from 'react'
import utils from '../../util/util'
import localizationUtil from '../../util/localizationUtil'
import Button from '../../components/Button'
import OverlayPopup from "../../components/OverlayPopup"
import ContactFormFullOverlay from '../../components/ContactFormFullOverlay'
import formConfig from '../../util/formIntegrations/forms.config'
export default class PricesESignWebserviceApi extends React.Component {
  render() {
    const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale
    const fields = utils.getDocument('pages_' + language, 'prices_e-sign_webservice_api')

    return (<main className='prices prices-e-sign-webservice'>
      <h1>
        {fields.headline}
      </h1>
      <p className='main-description'>{fields.description}</p>
      <div className='flex'>
        {fields.price_card.map((content, index) => {
          return (
            <article key={index} className='cell'>
              <h3>{content.headline}</h3>
              <p className='offer'>{content.offer}</p>
              <hr />
              <p className='description'>{content.description}</p>
              <ul>
                {content.list.map((listItem, index) => {
                  return <li key={listItem.li + index}>	&#10004; {listItem.li}</li>
                })}
              </ul>
              <Button
                overlay={<OverlayPopup component={
                  <ContactFormFullOverlay
                    type={index < 2 ? formConfig.types.order : formConfig.types.contact}
                    formSpreeEndPoint={index < 2 ? formConfig.formSpree.orderForm : formConfig.formSpree.contactForm}
                    hasCustomContent={index < 2 ? false : true}
                    formHeadline={index < 2 ? false : content.formHeadline}
                    formContent={index < 2 ? false : content.formContent}
                    productName={content.headline} />}
                  portalAdd={true}
                  closeOption={true}
                />}
                className='button button-green' text={content.openFormButton} />
            </article>
          )
        })}
      </div>
    </main>)
  }
}