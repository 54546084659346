import React from 'react'

export default class DarkBackGround extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'none'
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ display: nextProps.hide })
  }

  hide() {
    this.setState({
      display: 'none'
    })
  }

  show() {
    this.setState({
      display: 'flex'
    })
  }

  render() {
    return (
      <div>
        <button className={this.props.buttonStyle} onClick={() => { this.show() }}>{this.props.buttonText}</button>
        <div className='dark-background-wrapper' style={this.state}>
          <div onClick={() => { this.hide() }} className='dark-background' >
          </div >
          {/* content can not be nested due to onClick inheritance */}
          {this.props.content}
        </div>
      </div>
    )
  }
}