import React from 'react'
import { slugify } from '../util/url'
import { NavLink } from 'react-router-dom'
import RenderField from "../components/RenderField"
import util from '../util/util'

export default class CasesList extends React.Component {
  render() {
    var casesList = this.props.casesList;
    const fields = this.props.fields
    const getRouteByID = util.getRouteByID

    let orderToRender = [
      "sigillet fastighets ab",
      "huddinge-samhallsfastigheter",
      "helsingborgshem",
      "stockholmshem",
      "toyota",
      "vasyd",
      "st1",
      "tidvis",
      "unit4",
      "xl-bygg",
      "moneygo",
    ]
    //if an id does not exist in the order it gets puhsed to the last place in the list.
    casesList.forEach(kundCase => {
      if (!orderToRender.includes(kundCase.id)) {
        orderToRender.push(kundCase.id)
      }
    });

    const casesInOrder = (orderToRender, casesList) => {
      let newCasesList = [];
      orderToRender.forEach((id) => {
        casesList.forEach(cases => {
          if (id === cases.id) {
            newCasesList.push(cases)
          }
        });
      });
      return newCasesList
    }

    const newCasesList = casesInOrder(orderToRender, casesList)
    // render cases
    const casesToRender = newCasesList.map((individualCase, index) => {
      return (
        <React.Fragment key={individualCase.title}>
          <article>
            <NavLink to={slugify(`${getRouteByID('customerCases')}/${individualCase.title}`)}>
              {typeof individualCase.postFeaturedImage !== "undefined"
                && <img src={individualCase.postFeaturedImage} alt={individualCase.altText !== undefined ? individualCase.altText : ''} />
              }

              <p className="case-title">{individualCase.title}</p>
            </NavLink>
            <RenderField source={individualCase.excerpt} />
            <NavLink to={slugify(`${getRouteByID('customerCases')}/${individualCase.title}`)}><strong className='fat-link'>{fields.readMoreLink} &gt;&gt;</strong></NavLink>
          </article>
          {index !== casesList.length - 1 ? < hr /> : ''}
        </React.Fragment>
      )
    })
    return (
      <>
        <main>
          <h1>{fields.headline}</h1>
          {casesToRender}
        </main>
      </>
    )
  }
}