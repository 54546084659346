import postForm from './postData'

export default (endpoints, event) => {
  event.preventDefault();
  const form = event.target

  const postCalls = endpoints.map(endpoint => {
    return postForm(endpoint.prepareData(form), endpoint.url)
  })

  const isResolved = Promise.all(postCalls).then(responses => {
    return responses.some((response, index) => {
      return endpoints[index].validate(response)
    })
  })

  if (isResolved) form.reset()

  return isResolved
}