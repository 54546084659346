import React from 'react';
import Slider from "react-slick";
import RenderField from '../../components/RenderField'
export default class SliderUploadFiles extends React.Component {
  render() {
    const slickSettings = {
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      lazyLoad: "progressive",
      arrows: true
    }

    const fields = this.props.fields
    return (<>
      <div className='cell phone-cell'>
        <Slider className="single-item" {...slickSettings}>
          {fields.tjanstenslider2.map((sliderImage, index) => {
            return (
              <React.Fragment key={"tjansten1" + index}>
                <div className={"screen screen-0" + (index + 1)}>
                  <img src={sliderImage.sliderImages} alt={sliderImage.imageAltDescription}
                    id={"screen-0" + (index + 1)} />
                </div>
              </React.Fragment>
            )
          })}
        </Slider>
      </div>
      <div className="text cell">
        <h2>{fields.sliderUploadFilesSectionHeadline}</h2>
        <RenderField source={fields.sliderUploadFilesSectionDescription} />
        <div className="customer-quote"><RenderField source={fields.sliderUploadFilesSectionQuote} /></div>
        <div className="customer">
          <img id='image-of-cissi' src={fields.sliderUploadFilesSectionImage} alt={fields.sliderUploadFilesSectionImageAltText} />
          <div className='name-and-title-field'>
            <RenderField source={fields.sliderUploadFilesSectionFullName} />
          </div>
        </div>
      </div>
    </>)
  }
}