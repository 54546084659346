import data from '../../src/data.json';
import localizationUtil from './localizationUtil';
import languageConfig from '../language.config';

var util = {
    getDocument: function (collection, name) {
        return data[collection] && data[collection].filter(page => page.name === name)[0]
    },
    getDocuments: function (collection) {
        return data[collection] || [];
    },
    getRouteByID: (id) => {
        const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
        const routes = util.getDocument('routing_' + language, 'routing')

        const filterListByID = (linkObject) => {
            return linkObject.id === id
        }

        return routes.links.find(filterListByID).link
    },
    getRouteToSubPageByIDs: (...routeIDs) => {
        return routeIDs.reduce((previousRoute, currentRoute) => util.getRouteByID(previousRoute) + util.getRouteByID(currentRoute));
    },
    getMultiLanguageRoutesByID: (id, languageToExclude) => {
        const enabledLanguages = languageConfig.filter(langObject => langObject.enabledForFrontend === true && langObject.name !== languageToExclude)
        const paths = enabledLanguages.map(langObject => {
            const routes = util.getDocument('routing_' + langObject.locale, 'routing')

            return routes.links.find(link => link.id === id).link
        })

        return paths
    },
    getRouteTextByID: (id) => {
        const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
        const routes = util.getDocument('routing_' + language, 'routing')
        const filterListByID = (linkObject) => {
            return linkObject.id === id
        }

        return routes.links.find(filterListByID).text
    }
}

export default util;
