import React from 'react'
import RenderField from '../../components/RenderField'
import { slugify } from '../../util/url'
import Arrow from '../../components/Arrows'
export default class LegalAndTechnicalSecurity extends React.Component {
  render() {
    const {
      bindingAgreements,
      dataProtection,
      legislationHeadline,
      eIDAS,
      eventLog,
      evidenceRequirements,
      headline,
      informationSecurityHeadline,
      permissionManagement,
      personalDataProcessor,
      privacyByDesign,
      referenceDocumentation,
      representativeAgreement,
      routinesGovernance,
      sealing,
      secureCommunication,
      secureStorage,
      storageAndProcessing,
      storageTimes,
      technicalSafety,
      indexHeadline,
      index
    } = this.props.fields

    const scrollToElement = (id) => {
      const element = document.querySelector(`#${id}`)
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    }

    return (<main className='legal-and-technical-page standard-main'>
      <span id='top' className='anchor-standard'>scroll to me</span>
      <h1>{headline}</h1>
      <section className='index'>
        <h3>{indexHeadline}</h3>
        <ul>
          {index.legislation.map((hashLink, index) => {
            return (<li key={hashLink + index}><span onClick={() => { scrollToElement(slugify(hashLink.text).replace('/', '-')) }}>{hashLink.text}</span></li>)
          })}
          {index.informationSecurity.map((hashLink, index) => {
            return (<li key={hashLink + (index + 1)}><span onClick={() => { scrollToElement(slugify(hashLink.text)) }}>{hashLink.text}</span></li>)
          })}
        </ul>
      </section>
      <span id={slugify(index.legislation[0].text)} className='anchor-standard'>scroll to me</span>
      <section className='eIDAS-section'>
        <h2>{legislationHeadline}</h2>
        <RenderField source={eIDAS} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='binding-agreements-section'>
        <span id={slugify(index.legislation[1].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={bindingAgreements} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='evidenceRequirements-section'>
        <span id={slugify(index.legislation[2].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={evidenceRequirements} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='eventLog-section'>
        <span id={slugify(index.legislation[3].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={eventLog} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='sealing-section'>
        <span id={slugify(index.legislation[4].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={sealing} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='referenceDocumentation-section'>
        <span id={slugify(index.legislation[5].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={referenceDocumentation} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='dataProtection-section'>
        <span id={slugify(index.legislation[6].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={dataProtection} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='representativeAgreement-section'>
        <span id={slugify(index.legislation[7].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={representativeAgreement} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='privacyByDesign-section'>
        <span id={slugify(index.legislation[8].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={privacyByDesign} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='storageTimes-section'>
        <span id={slugify(index.legislation[9].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={storageTimes} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='technicalSafety-section'>
        <span id={slugify(index.legislation[10].text).replace('/', '-')} className='anchor-standard'>scroll to me</span>
        <RenderField source={technicalSafety} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='personalDataProcessor-section'>
        <span id={slugify(index.legislation[11].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={personalDataProcessor} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='storageAndProcessing-section'>
        <span id={slugify(index.legislation[12].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={storageAndProcessing} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='secureCommunication-section'>
        <h2>{informationSecurityHeadline}</h2>
        <span id={slugify(index.informationSecurity[0].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={secureCommunication} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='secureStorage-section'>
        <span id={slugify(index.informationSecurity[1].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={secureStorage} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='permissionManagement-section'>
        <span id={slugify(index.informationSecurity[2].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={permissionManagement} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
      <section className='routinesGovernance-section'>
        <span id={slugify(index.informationSecurity[3].text)} className='anchor-standard'>scroll to me</span>
        <RenderField source={routinesGovernance} />
        <span className='arrow-span' onClick={() => { scrollToElement(slugify('top')) }}><Arrow direction='up' size='big' /></span>
      </section>
    </main>)
  }
}
