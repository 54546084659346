import React from 'react'
import RenderField from '../../../components/RenderField'

export default class AccountMissing extends React.Component {
  render() {
    const fields = this.props.fields

    return (<main className='account-missing'>
      <article>
        <section>
          <h1>{fields.headLine}</h1>
          <RenderField source={fields.content} />
        </section>
      </article>
    </main>)
  }
}
