import React from 'react';
import RenderField from "../components/RenderField";

export default class ClassicError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: this.getParameterContentByName('errorCode') || 'okänd',
            errorMessage: this.getParameterContentByName('errorMessage') || '',
        }
    }

    getParameterContentByName = (name) => {
        var match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    }

    render() {
        var fields = this.props.fields;
        return (
            <main className='calssical-error-main'>
                <article>
                    <RenderField source={fields.classicErrorText} />
                    <p>Felkod: <span>{this.state.errorCode}</span></p>
                    <p>{this.state.errorMessage}</p>
                </article>
            </main>
        )
    }
}
