var helpers = {
  isHTML: function (str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }
    return false;
  },
  openOverlay: function () {
    document.body.classList.add('overlay-active');
  },
  closeOverlay: function () {
    document.body.classList.remove('overlay-active');
  }
}
export default helpers;