import React from 'react'
import Table from './Table'
import RenderField from '../../components/RenderField'
export default class SocialMediaSection extends React.Component {
  render() {
    const { socialMediaHeadline, socialMediaDescription, socialMediaTable } = this.props.fields
    return (<>
      <h2>{socialMediaHeadline}</h2>
      <RenderField source={socialMediaDescription} />
      <Table tableData={socialMediaTable} />
    </>)
  }
}