import React from 'react'
import SearchBar from './SearchBar'
import RenderField from '../../components/RenderField'
import util from '../../util/util'
import ContactInformation from './ContactInformation'
import { NavLink } from 'react-router-dom'
import { slugify } from '../../util/url'
export default class FAQ extends React.Component {
  constructor(props) {
    super(props)
    this.sectionRefs = props.fields.sections.map((section) => {
      const ref = React.createRef()
      const refPlusSign = React.createRef()
      return {
        ref: ref,
        refPlusSign: refPlusSign,
        questionsRef: section.h3_and_p.map((question) => {
          return {
            anchor: React.createRef(),
            questionRef: React.createRef(),
            refPlusSign: React.createRef(),
            questionID: slugify(question.headline),
            parentRef: ref,
            parentPlusSign: refPlusSign
          }
        })
      }
    }
    )

    this.toggleVisibility = (...elements) => {
      elements.forEach(element => {
        const ref = element.current
        ref.classList.value.includes('hidden') ? ref.classList.remove('hidden')
          :
          elements.length < 2 && ref.classList.add('hidden')
      })
    }

    this.togglePlusAndMinusSign = (...elements) => {
      elements.forEach(element => {
        const ref = element.current
        if (ref.innerHTML === '+') {
          ref.innerHTML = '&#8722;'
        } else {
          if (elements.length < 2) ref.innerHTML = '&#43;'
        }
      })
    }

    this.inToView = (element, fromQuestion) => {
      const ref = element.current
      if (fromQuestion) {
        ref.style.top = '-125px'
        ref.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
      } else {
        ref.style.top = '-350px'
        ref.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
      }
    }
  }

  componentDidMount() {
    if (this.props.location.hash) {
      const cleanHashTag = this.props.location.hash.replace('#', '')
      const questionToOpen = this.sectionRefs.map(section => {
        return section.questionsRef
      }).reduce((base, questions) => {
        return base.concat(questions)
      }).filter(question => {
        return question.questionID === cleanHashTag
      })
      const { questionRef, parentRef, refPlusSign, parentPlusSign, anchor } = questionToOpen[0]
      setTimeout(() => {
        this.toggleVisibility(parentRef, questionRef)
        this.togglePlusAndMinusSign(refPlusSign, parentPlusSign)
        this.inToView(anchor, true)
      }, 300);
    }
  }

  render() {
    const { fields } = this.props
    const sections = fields.sections
    const { getRouteByID, getRouteTextByID } = util

    return (
      <main className='new-faq-page' >
        <h1>{fields.headline}</h1>
        <SearchBar fields={fields} inToView={this.inToView} sectionRefs={this.sectionRefs} toggleVisibility={this.toggleVisibility} togglePlusAndMinusSign={this.togglePlusAndMinusSign} />
        <p>{fields.categoryDescription}</p>
        {sections.map((section, sectionIndex) => {
          const questions = section.h3_and_p
          return (<section key={section.id}>
            <h2
              onClick={() => {
                this.toggleVisibility(this.sectionRefs[sectionIndex].ref);
                this.togglePlusAndMinusSign(this.sectionRefs[sectionIndex].refPlusSign)
              }}
              className='h2-faq-page'
            >
              <span
                ref={this.sectionRefs[sectionIndex].refPlusSign}
                className='plus-sign'>
                &#43;
              </span>
              {section.headline}
            </h2>
            {/* below is inside of the section */}
            <div className='hidden' ref={this.sectionRefs[sectionIndex].ref} >
              {questions.map((question, questionIndex) => {
                return (
                  <div key={question.headline}>
                    <span
                      ref={this.sectionRefs[sectionIndex].questionsRef[questionIndex].anchor}
                      className='anchor' >
                      scroll to me
                    </span>
                    <h3
                      onClick={() => {
                        this.toggleVisibility(this.sectionRefs[sectionIndex].questionsRef[questionIndex].questionRef);
                        this.togglePlusAndMinusSign(this.sectionRefs[sectionIndex].questionsRef[questionIndex].refPlusSign)
                      }}
                      className='button-h3'>
                      <span ref={this.sectionRefs[sectionIndex].questionsRef[questionIndex].refPlusSign}
                        className='plus-sign'>
                        &#43;
                      </span>
                      <span id={slugify(question.headline)}>{question.headline}</span>
                    </h3>
                    <div ref={this.sectionRefs[sectionIndex].questionsRef[questionIndex].questionRef}
                      className='small-section-content hidden'>

                      <RenderField source={question.paragraph} />
                      {
                        question.keywords &&
                        question.keywords.some(obj => {
                          return obj.word === 'has-api-documentation-button'
                        }) && <span className='api-button-wrapper'>
                          <a href={getRouteByID('apiDocumentation')} className='api-button'>{getRouteTextByID('apiDocumentation')}</a>
                        </span>
                      }

                      <NavLink to={`${getRouteByID('faq')}/${slugify(question.headline)}`}>{fields.readMoreLink}</NavLink>
                    </div>
                  </div>)
              })}
            </div>
          </section>)
        })}
        <section className='contact-info'>
          <ContactInformation fields={fields} />
        </section>
      </main >
    )
  }
}
