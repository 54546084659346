import React from 'react';
import localizationUtil from '../../util/localizationUtil';
import utils from '../../util/util';
import RenderField from "../RenderField";
export default class Developer extends React.Component {
    render() {
        const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale;
        const fields = utils.getDocument('pages_' + language, 'utvecklare')
        return (
            <article className='developers-wrapper'>
                <RenderField source={fields.excerpt} />
                <a className='button button-green' href='https://api-docs.egreement.com/#introduction'>{fields.linkTextToDocumentation}</a>
                <RenderField source={fields.codeExample} />
            </article>
        )
    }
}