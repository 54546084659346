import React from 'react'
import util from '../../util/util'

export default class ContactInformation extends React.Component {
  render() {
    const { getRouteByID, getRouteTextByID } = util
    const { fields } = this.props

    return (<>
      <h2>{fields.contactUsHeader}</h2>
      <p>
        {fields.description}
      </p>
      <p>{fields.beforePhoneLink} <a href={getRouteByID('phoneLinkToOffice')}>{getRouteTextByID('phoneLinkToOffice')}</a></p>
      <p>
        {fields.textForPhoneSupport}
      </p>
      <p>
        {fields.beforeEMailLink} <a href={getRouteByID('supportEMailLink')}> {getRouteTextByID('supportEMailLink')}</a>
      </p>
    </>)
  }
}