import React from 'react'
import Table from './Table'

export default class CustomerSupportSection extends React.Component {
  render() {
    const { customerSupportSecondTable, } = this.props.fields

    return (
      <>
        <Table tableData={customerSupportSecondTable} />
      </>
    )
  }
}
