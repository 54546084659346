import React from "react";
import RenderField from '../../components/RenderField'

export default class MapOfServiceSection extends React.Component {
  render() {
    const fields = this.props.fields

    return (<>
      <h2>{fields.mapOfServiceSectionHeadline}</h2>
      <RenderField source={fields.mapOfServiceSectionDescription} />
      <img id='map-of-service-illustration' src={fields.mapOfServiceSectionImage} alt={fields.mapOfServiceSectionImageAltText} />
    </>)
  }
}