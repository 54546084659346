import React from 'react'
import RenderField from '../../components/RenderField'
import AllNeedsSection from './AllNeedsSection'
import CustomerCase from './CustomerCaseSection'
import ContactSection from './ContactSection'
import DemoForm from '../../components/DemoForm'
import Developer from '../../components/Developer'
export default class ESignAPI extends React.Component {
  render() {
    const fields = this.props.fields

    return (<main className='e-sign-api-page'>
      <section className='main-section'>
        <div className='main-section-text'>
          <RenderField source={fields.headline} />
          <RenderField source={fields.description} />
        </div>
        <DemoForm />
      </section>
      <section className='all-needs-section'>
        <AllNeedsSection fields={fields} />
      </section>
      <hr className='orange' />
      <section className='developer-section'>
        <Developer fields={fields} />
      </section>
      <hr className='orange' />
      <section className='customer-case'>
        <CustomerCase fields={fields} />
      </section>
      <section className='contact-section'>
        <ContactSection fields={fields} />
      </section>
    </main>)
  }
}