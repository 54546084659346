import React from 'react'
import Table from './Table'

export default class CustomerSupportSection extends React.Component {
  render() {
    const { customerSupportHeadline, customerSupportTable } = this.props.fields

    return (
      <>
        <h2>{customerSupportHeadline}</h2>
        <Table tableData={customerSupportTable} />
      </>
    )
  }
}
