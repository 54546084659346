import React from 'react'
import RenderField from '../../components/RenderField'

export default class SummarySection extends React.Component {
  render() {
    const { summary } = this.props.fields

    return (<>
      <RenderField source={summary} />
    </>)
  }
}