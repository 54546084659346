import React from 'react'
import RenderField from '../../components/RenderField'
import SafetyAndSecuritySection from './SafetyAndSecuritySection'
import PopularFunctionsSection from './PopularFunctionSection'
import CustomerCaseSection from './CustomerCaseSection'
import ContactSection from './ContactSection'
import DemoForm from '../../components/DemoForm'
export default class BankIDPage extends React.Component {
  render() {
    const fields = this.props.fields

    return (<main className='bank-id-page'>
      <section className='main-section'>
        <div className='main-section-text'>
          <RenderField source={fields.headline} />
          <RenderField source={fields.description} />
        </div>
        <DemoForm />
      </section>
      <section className='safety-and-security-section'>
        <SafetyAndSecuritySection fields={fields} />
      </section>
      <hr className='orange' />
      <section className='popular-functions-section'>
        <PopularFunctionsSection fields={fields} />
      </section>
      <hr className='orange' />
      <section className='customer-case-section'>
        <CustomerCaseSection fields={fields} />
      </section>
      <section className='contact-section'>
        <div className='contact-section-content'>
          <ContactSection fields={fields} />
        </div>
      </section>
    </main>)
  }
}