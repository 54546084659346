import React from 'react';
import util from '../../src/util/util';
import localizationUtil from '../util/localizationUtil';
import RenderField from "../components/RenderField";
import resolveFormPosts from '../util/formIntegrations/resolveFormPosts'
import { TextArea, Input } from './formComponents/'
import formConfig from '../util/formIntegrations/forms.config'
export default class ContactFormFullOverlay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            allowFormSubmission: true
        };
    }

    submitForm(event, fields) {
        const { formSpreeEndPoint } = this.props

        resolveFormPosts([formSpreeEndPoint], event).then(isResolved => {
            this.setState({
                formSubmissionResult: isResolved ? fields.formsuccessmsg : fields.formerrormsg,
                allowFormSubmission: isResolved ? false : true
            })
        })
    }

    render() {
        const language = localizationUtil.getUserLanguagePreference(window, localStorage).locale
        const fields = this.props.fields || util.getDocument('component_' + language, this.props.type || formConfig.types.contact)
        const buttonColor = this.props.productName === "Start" ? " button-green" : " button-blue"
        const { name, message, email, company, phone, productDescription } = formConfig.inputNames
        const description = this.props.formHeadline || `${fields.contactFormHead} ${this.props.productName}`

        return (
            <>
                {
                    (this.props.hasCustomContent ?
                        <div className='cell'>
                            <RenderField source={this.props.formHeadline} />
                            <RenderField source={this.props.formContent} />
                        </div>
                        :
                        <div className='cell'>
                            <h2>{`${fields.contactFormHead} ${this.props.productName}`}</h2>
                            <RenderField source={fields.contactFormPara} />
                            <RenderField source={fields.contactFormList} />
                        </div>
                    )
                }

                <div className='cell'>
                    <div className="contactform contactform__full">
                        <form onSubmit={(event) => { this.submitForm(event, fields) }}>
                            <div className="form-container">
                                <Input
                                    type="text"
                                    name={name}
                                    placeholder={fields.formNamePlaceholder}
                                    errorMessage={fields.formNameErr}
                                />
                                <Input
                                    type="email"
                                    name={email}
                                    placeholder={fields.formEmailPlaceholder}
                                    errorMessage={fields.formEmailErr}
                                />
                                <Input
                                    type="text"
                                    name={company}
                                    placeholder={fields.formCompanyPlaceholder}
                                    errorMessage={fields.formCompanyErr}
                                />
                                <Input
                                    type="tel"
                                    name={phone}
                                    pattern='\+?[0-9]{5,13}'
                                    placeholder={fields.formphonePlaceholder}
                                    errorMessage={fields.formphoneErr}

                                />

                                <Input
                                    visible={false}
                                    required={false}
                                    type='text'
                                    name={productDescription}
                                    defaultValue={description}
                                />

                                <TextArea
                                    label={fields.formMoreInfo}
                                    name={message}
                                    rows="3"
                                />
                            </div>
                            <p>
                                {this.state.formSubmissionResult}
                            </p>
                            <div className="terms">
                                <RenderField source={fields.contactFormTerms} enderEncloser='span' />
                            </div>
                            <button className={`button  ${buttonColor}`} disabled={!this.state.allowFormSubmission}>{fields.contactFormButton}</button>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}