import React from 'react'
import RenderField from '../RenderField'
import { NavLink } from 'react-router-dom';
import { slugify } from '../../util/url'

export default class IDCard extends React.Component {
  render() {
    return (<div>
      <div className="ID-card-upper-half">
        <img src={this.props.img} alt={this.props.image_alt_text} />
        <h3>{this.props.headline}</h3>
        <RenderField source={this.props.content} />
      </div>
      <hr className='orange' />
      <div className='lower-half'>
        <RenderField source={this.props.checklist} />
      </div>
      <NavLink className='button button-green id-card-button read-more-link' to={slugify(`${this.props.buttonLink}`)}>{this.props.buttonText}</NavLink>
    </div>)
  }
}