import React from 'react'
import RenderField from '../../components/RenderField'
import { slugify } from '../../util/url';
import { NavLink } from 'react-router-dom';
import util from '../../util/util';

export default class ListOfEgreementServices extends React.Component {
  scrollToList() {
    const listOfFunctionsButton = document.querySelector('#lista-over-egreements-tjanster')
    listOfFunctionsButton.scrollIntoView()
  }

  render() {
    const getRouteByID = util.getRouteByID
    const fields = this.props.fields

    var tableDataArray = fields.tableData;

    let categoryMappingForFunctions = []
    let currentCategory = ''

    tableDataArray.forEach(tableData => {
      if (tableData.tdDataHead.match(/'(.*?)'/g)) {
        const dataToSearch = tableData.tdDataHead.match(/'(.*?)'/g)
        currentCategory = dataToSearch[0].replace("'", '').replace("'", '')
      }

      categoryMappingForFunctions.push(currentCategory)
    })

    return (<div>
      <h2>{fields.tableSectionHeadline}</h2>
      <table className="table-functions">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className="rotate">
              <div>{fields.startHeader}</div>
            </th>
            <th className="rotate">
              <div>{fields.teamHeader}</div>
            </th>
            <th className="rotate">
              <div>{fields.connectHeader}</div>
            </th>
            <th className="rotate">
              <div>{fields.additionHeader}</div>
            </th>
          </tr>
        </thead>
        <tbody>

          {
            tableDataArray.map(
              (tableData, index) => {
                return (
                  <tr key={index}>
                    <td>

                      {tableData.nonLinkItem ?
                        <span
                          className='scroll-margin-top'
                          id={`${categoryMappingForFunctions[index].replace('ä', 'a').replace('ö', 'o').replace('ø', 'o')}`}>
                          <RenderField source={tableData.tdDataHead} />
                        </span>
                        :
                        <NavLink
                          to={{
                            pathname: slugify(`${getRouteByID('service')}/${tableData.tdDataHead}`),
                          }}>
                          <RenderField source={tableData.tdDataHead} />
                        </NavLink>}
                    </td>
                    <td><RenderField source={tableData.tdDataDesc} /></td>
                    <td><RenderField source={tableData.tdHeadValue1} /></td>
                    <td><RenderField source={tableData.tdHeadValue2} /></td>
                    <td><RenderField source={tableData.tdHeadValue3} /></td>
                    <td><RenderField source={tableData.tdHeadValue4} /></td>
                  </tr>
                )
              }
            )
          }
        </tbody>
      </table>
    </div >)
  }
}
