import React from 'react'
import DetailedDescriptionSection from './DetailedDescriptionSection'
import MainSection from './MainSection'
import SocialMediaSection from './SocialMediaSection'
import TestAccountSection from './TestAccountSection.js'
import EventSection from './EventSection.js'
import MarketingSection from './MarketingSection.js'
import IndirectContactSection from './IndirectContactSection.js'
import PublisherSection from './PublisherSection.js'
import PossibleClaimsSection from './PossibleClaimsSection.js'
import CustomerSupportSection from './CustomerSupportSection'
import CustomerSupportSecondSection from './CustomerSupportSecondSection.js'
import PersonalInformationSection from './PersonalInformationSection.js'
import BalanceOfInterestSection from './BalanceOfInterestSection.js'
import YourRightsSection from './YourRightsSection.js'
import SummarySection from './SummarySection.js'
export default class IntegrityPolicy extends React.Component {
  render() {
    const fields = this.props.fields

    return (<main className='integrityPolicyPage'>
      <article>
        <section className='main-section'>
          <MainSection fields={fields} />
        </section>
        <section className='summary-section'>
          <SummarySection fields={fields} />
        </section>
        <section className='detailed-description-section table-section-with-h2'>
          <DetailedDescriptionSection fields={fields} />
        </section>
        <section className='social-media-section table-section-with-h2'>
          <SocialMediaSection fields={fields} />
        </section>
        <section className='test-account-section table-section-with-h2'>
          <TestAccountSection fields={fields} />
        </section>
        <section className='event-account-section table-section-with-h2'>
          <EventSection fields={fields} />
        </section>
        <section className='marketing-section table-section-with-h2'>
          <MarketingSection fields={fields} />
        </section>
        <section className='indirect-contact-section table-section-with-h2'>
          <IndirectContactSection fields={fields} />
        </section>
        <section className='publisher-section'>
          <PublisherSection fields={fields} />
        </section>
        <section className='possible-claims-section'>
          <PossibleClaimsSection fields={fields} />
        </section>
        <section className='customer-support-section table-section-with-h2'>
          <CustomerSupportSection fields={fields} />
        </section>
        <section className='customer-support-second-section'>
          <CustomerSupportSecondSection fields={fields} />
        </section>
        <section className='personal-information-section text-section'>
          <PersonalInformationSection fields={fields} />
        </section>
        <section className='balance-of-section text-section'>
          <BalanceOfInterestSection fields={fields} />
        </section>
        <section className='your-rights-section last-section text-section'>
          <YourRightsSection fields={fields} />
        </section>
      </article>
    </main>)
  }
}
