import React from 'react';
import RenderField from "../components/RenderField";
import { NavLink } from 'react-router-dom'
import util from '../util/util'
import localizationUtil from '../util/localizationUtil';
import { slugify } from '../util/url'
export default class GeneralPage extends React.Component {
  constructor() {
    super()
    this.informationFromServicePage = util.getDocument(`pages_${localizationUtil.getUserLanguagePreference(window, localStorage).locale}`, 'tjansten')
  }

  findCurrentHash() {
    const informationFromServicePage = this.informationFromServicePage
    const tableDataArray = informationFromServicePage.tableData
    const baseNameFromLinkTitle = informationFromServicePage.baseNameForLinkTitle
    const pathName = this.props.location.pathname
    let currentCategory = ''
    let linkText = ''
    let hash = ''
    let title = baseNameFromLinkTitle

    tableDataArray.forEach(tableData => {
      if (tableData.tdDataHead.match(/id='(.*?)'/g)) {
        const dataToSearch = tableData.tdDataHead.match(/id='(.*?)'/g)
        currentCategory = dataToSearch[0].slice(4, dataToSearch[0].length - 1)
      }

      if (pathName.indexOf(slugify(tableData.tdDataHead.trim())) !== - 1) {
        linkText = currentCategory.charAt(0).toUpperCase() + currentCategory.slice(1).replace(/-/g, ' ')
        currentCategory = slugify(currentCategory)
        hash = currentCategory
      }
    })
    return { hash, linkText, title }
  }

  render() {
    const createBreadCrumb = () => {
      const hashAndLinkText = this.findCurrentHash()
      const linkText = hashAndLinkText.linkText
      const hash = hashAndLinkText.hash
      const title = `${hashAndLinkText.title} ${this.informationFromServicePage.titleTextForLinkGeneralPages}`
      const backLinkToServicePage = this.informationFromServicePage.linkBackToServicePage
      const baseNameForService = util.getRouteByID('service')

      return (<nav className='breadcrumb'>
        <NavLink title={`${title}`} className='back-to-service-arrow' to={backLinkToServicePage}>{'<'}</NavLink>
        <NavLink style={{ fontSize: '16px' }} to={{ pathname: baseNameForService, hash: `${hash}` }} >{linkText}</NavLink>
      </nav>)
    }

    const { fields } = this.props

    return (
      <main className='general-page-main'>
        {createBreadCrumb()}
        <article>
          <h1>{fields.title}</h1>
          <RenderField source={fields.text} />
        </article>
      </main>
    )
  }
}