import React from 'react'
import formsConfig from '../../../util/formIntegrations/forms.config'

const TextArea = (props) => {
  const { label, name, rows, placeholder, required } = props

  if (formsConfig.inputNames[name] !== name) {
    throw new Error(`
    Use an allowed name.
    get name from in forms.config.js.
  If your missing a field name add as needed`)
  }

  return (
    <>
      {label &&
        <label htmlFor="textarea">
          {label}
        </label>
      }

      <textarea
        name={name}
        id={name}
        rows={rows}
        placeholder={placeholder || ''}
        required={required !== false}
      ></textarea>
    </>
  )
}

export default TextArea