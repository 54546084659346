import React from 'react'
import Table from './Table'

export default class IndirectContactSection extends React.Component {
  render() {
    const { indirectContactHeadline, indirectContactTable } = this.props.fields

    return (
      <>
        <h2>{indirectContactHeadline}</h2>
        <Table tableData={indirectContactTable} />
      </>
    )
  }
}
