import React from 'react'
import RenderField from '../../components/RenderField'

export default class DigitalPercs extends React.Component {

  render() {
    const fields = this.props.fields
    const cards = fields.check_mark_card

    return (<>
      <h2>{fields.digital_percs_section_headline}</h2>
      <div className='flex'>
        {cards.map((card, index) => {
          return <div key={index}>
            <div className='check-mark'>&#10004;</div>
            <RenderField source={card.card_content} />
          </div>
        })}
      </div>
    </>
    )
  }
}