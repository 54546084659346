import React from 'react'
import Table from './Table'

export default class MarketingSection extends React.Component {
  render() {
    const { marketingHeadline, marketingTable } = this.props.fields

    return (
      <>
        <h2>{marketingHeadline}</h2>
        <Table tableData={marketingTable} />
      </>
    )
  }
}
